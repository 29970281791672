import React, { useEffect, useState } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import { Button } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const HRBPDataGrid = ({
    shortlistRef,
    userSFID,
    nominatedArray,
    setNominatedArray,
    mySplit,
    splitDetails
}) => {
    const [shortListedFromUnitHead, loading, error] = useCollectionData(
        trainingParticipantListsCollectionRef
            .doc(shortlistRef)
            .collection(shortlistRef)
            .where("hrbpSF", "==", userSFID)
    );

    const [shortListed, setShortListed] = useState(null)

    useEffect(()=>{
        if(shortListedFromUnitHead && shortListedFromUnitHead.length>0){
            const filteredItems = shortListedFromUnitHead.filter(item => item.nominated || item.waiting)
            setShortListed(filteredItems)
        }
    },[shortListedFromUnitHead])

    const [nominatedCount, setNominatedCount] = useState(null);
    const [mySplitCount, setMySplitCount] = useState(null)
    const [waitingMode, setWaitingMode] = useState(null);

    useEffect(()=>{
        if(mySplit && splitDetails){
            const ownSplit = splitDetails[mySplit]
            setMySplitCount(ownSplit)
        }
    },[splitDetails, mySplit])

    trainingParticipantListsCollectionRef
        .doc(shortlistRef)
        .collection(shortlistRef)
        .where("hrbpSF", "==", userSFID)
        .get()
        .then(() => {
            console.log("here");
        });

    const columns = [
        { key: "SFID", name: "SFID" },
        { key: "name", name: "Name", resizable: true },
        { key: "payGrade", name: "Grade", sortable: true },
        { key: "department", name: "Function" },
        // {
        //     key: "priority",
        //     name: "Priority",
        //     width: 280,
        //     resizable: true,
        //     renderCell: (params) => (
        //         <>
        //             <PrioritySelectorUnitHead
        //                 data={params.row}
        //                 docRef={shortlistRef}
        //             />
        //         </>
        //     ),
        // },
        {
            key: "status",
            name: "Status",
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <NominationStatusComp
                        data={params.row}
                        docRef={shortlistRef}
                    />
                </>
            ),
        },
        {
            key: "action",
            name: "Action",
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <NominateIndividual
                        data={params.row}
                        docRef={shortlistRef}
                        waitingMode={waitingMode}
                    />
                </>
            ),
        },
        { key: "hrbpName", name: "HRBP", minWidth:140 },
        { key: "unitHeadName", name: "Unit Head", minWidth:140 },
    ];
    useEffect(() => {
        if (shortListed && shortListed.length > 0) {
            const nominatedList = shortListed.filter(
                (item) => item.nominated === true
            );
            // alert(JSON.stringify(nominatedList.length));
            setNominatedArray([...nominatedList]);
            setNominatedCount(nominatedList.length);
        }
    }, [shortListed]);

    useEffect(() => {
        if (nominatedCount && mySplitCount) {
            if (nominatedCount === mySplitCount || nominatedCount > mySplitCount) {
                setWaitingMode(true);
            } else {
                setWaitingMode(false);
            }
        }
    }, [nominatedCount, mySplitCount]);

    return (
        <>
            {waitingMode ? "Waiting" : "No waiting"}

            {mySplit}
            {shortListed && mySplit && shortListed.length > 0 && (
                <DataGrid
                    className="rdg-light"
                    height={900}
                    style={{ height: "450px" }}
                    columns={columns}
                    rows={shortListed.sort((a, b) => {
                        return a.priority - b.priority;
                    })}
                    enableVirtualization
                    enableFilters
                />
            )}
        </>
    );
};

export default HRBPDataGrid;

const NominationStatusComp = ({ data, docRef }) => {
    return (
        <>
            {data.nominated ? (
                <span className="btn btn-success btn-smaller">Nominated</span>
            ) : data.waiting ? (
                <span className="btn btn-warning btn-smaller">
                    Waiting List
                </span>
            ) : (
                ""
            )}
        </>
    );
};

const PrioritySelectorUnitHead = ({ data, docRef }) => {
    const [employeeStatus, setEmployeeStatus] = useState(null);
    useEffect(() => {
        if (data) {
            setEmployeeStatus(data);
        }
    }, [data]);
    const onChangePriority = (priorityValue) => {
        // trainingParticipantListsCollectionRef
        //     .doc(docRef)
        //     .collection(docRef)
        //     .doc(employeeStatus.SFID)
        //     .update({
        //         priority: Number(priorityValue),
        //     });
    };
    return (
        <>
            {employeeStatus && (
                <ButtonGroup aria-label="Basic example">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                        <Button
                            disabled
                            onClick={() => onChangePriority(item)}
                            size="sm"
                            variant={`${
                                employeeStatus.priority === item
                                    ? "primary"
                                    : "light"
                            }`}
                        >
                            {item}
                        </Button>
                    ))}
                </ButtonGroup>
            )}
        </>
    );
};

const NominateIndividual = ({ data, docRef, waitingMode }) => {
    const [employeeStatus, setEmployeeStatus] = useState(null);
    useEffect(() => {
        if (data) {
            setEmployeeStatus(data);
        }
    }, [data]);
    const onNominationChange = (value) => {
        if (value) {
            trainingParticipantListsCollectionRef
                .doc(docRef)
                .collection(docRef)
                .doc(employeeStatus.SFID)
                .update({
                    nominated: value,
                    waiting: !value,
                });
        } else {
            trainingParticipantListsCollectionRef
                .doc(docRef)
                .collection(docRef)
                .doc(employeeStatus.SFID)
                .update({
                    nominated: value,
                    waiting: !value,
                });
        }
    };

    return (
        <>
            {employeeStatus && (
                <ButtonGroup aria-label="Basic example">
                    {employeeStatus.nominated ? (
                        <button
                            onClick={() => onNominationChange(false)}
                            className="btn btn-smaller btn-danger"
                        >
                            Remove
                        </button>
                    ) : waitingMode ? (
                        <button
                            onClick={() => onNominationChange(false)}
                            className="btn btn-smaller btn-outline-warning"
                        >
                            Waiting List
                        </button>
                    ) : (
                        <button
                            onClick={() => onNominationChange(true)}
                            className="btn btn-smaller btn-outline-primary"
                        >
                            Nominate
                        </button>
                    )}
                </ButtonGroup>
            )}
        </>
    );
};
