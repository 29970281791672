import firebase from "firebase/app";

export function excelDateToFirebaseTimestamp(excelDate) {
    const excelEpoch = new Date(1900, 0, 1);
    const unixEpoch = new Date(1970, 0, 1);
    const delta = unixEpoch - excelEpoch;
    const timestampSeconds = excelDate * 24 * 3600 * 1000 + delta;
    return new firebase.firestore.Timestamp(timestampSeconds / 1000, 0);
}

export function firebaseTimestampToExcelDate(firebaseTimestamp) {
    const unixEpoch = new Date(1970, 0, 1);
    const excelEpoch = new Date(1900, 0, 1);
    const timestampSeconds =
        firebaseTimestamp.seconds + firebaseTimestamp.nanoseconds / 1e9;
    const delta = unixEpoch - excelEpoch;
    const excelDays = (timestampSeconds - delta / 1000) / (24 * 3600);
    return excelDays;
}

export const collectionSanitizerToExcel = (array, dateField, dateFieldTwo) => {
    if (dateField && dateFieldTwo) {
        const sanitizedCollection = array.map((data) => {
            return {
                ...data,
                [dateField]: jsDateToExcelDate(
                    timeStampToJSDate(data[dateField])
                ),
                [dateFieldTwo]: jsDateToExcelDate(
                    timeStampToJSDate(data[dateFieldTwo])
                ),
            };
        });
        return sanitizedCollection;
    } else {
        const sanitizedCollection = array.map((data) => {
            return {
                ...data,
                [dateField]: jsDateToExcelDate(
                    timeStampToJSDate(data[dateField])
                ),
            };
        });
        return sanitizedCollection;
    }
    // alert(JSON.stringify(sanitizedCollection[0].dob))
};

export const timeStampToJSDate = (timeStamp) => {
    if (timeStamp) {
        const milliseconds = timeStamp.seconds * 1000;
        const tt = new Date(milliseconds);
        return tt;
    }
};
export const timeStampToDateString = (timeStamp) => {
    if (timeStamp) {
        const milliseconds = timeStamp.seconds * 1000;
        const tt = new Date(milliseconds);
        const dateString = tt.toDateString();
        return dateString;
    }
};

export function excelDateToJsDateConverter(excelDate) {
    const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel's epoch is one day before the JavaScript epoch (January 1, 1900)
    const milliseconds = excelDate * (24 * 60 * 60 * 1000); // Convert Excel days to milliseconds
    const jsDate = new Date(excelEpoch.getTime() + milliseconds);
    return jsDate;
}


export function excelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;
    const dateNumber = date_info.getDate();
    const monthNumber = date_info.getMonth() + 1;
    const yearNumber = date_info.getFullYear();

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    // return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    const finalDate = dateNumber + "/" + monthNumber + "/" + yearNumber
    // alert(finalDate)
    return dateNumber + "/" + monthNumber + "/" + yearNumber;
}

export function jsDateToExcelDate(jsDate) {
    const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel's epoch is one day before the JavaScript epoch (January 1, 1900)
    const delta = jsDate - excelEpoch;
    const excelDays = delta / (24 * 60 * 60 * 1000); // Convert milliseconds to days
    return excelDays;
}

// Usage
const myJsDate = new Date(); // Replace this with your JavaScript Date object
const excelDateValue = jsDateToExcelDate(myJsDate);
console.log(excelDateValue);
export const ExcelDateTillDateYears = (serial) => {
    // Get the Excel date value
    var excelDateValue = serial;

    // Convert Excel date value to JavaScript date object
    var excelDate = new Date((excelDateValue - (25567 + 1)) * 86400 * 1000);

    // Get the current date
    var currentDate = new Date();

    // Calculate the difference in years
    var diffYears = currentDate.getFullYear() - excelDate.getFullYear();
    var diffMonths = (currentDate.getMonth() - excelDate.getMonth()) / 12;
    diffYears = diffYears + Math.ceil(diffMonths * 100) / 100;
    // Check if the current date has not reached the month and day of the Excel date
    if (
        currentDate.getMonth() < excelDate.getMonth() ||
        (currentDate.getMonth() === excelDate.getMonth() &&
            currentDate.getDate() < excelDate.getDate())
    ) {
        diffYears--;
    }

    // The variable diffYears now holds the number of years until the current date
    console.log(diffYears);

    return Math.ceil(diffYears * 100) / 100;
};
