import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import AddTrainingModulePopup from "./AddTrainingModulePopup";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { trainingModulesCollectionRef } from "../../firebase/fire-app";
import "./TrainingModule.scss"


const ViewTrainingModules = () => {
    const [allTrainings, loading, error] = useCollectionData(
        trainingModulesCollectionRef,
        { idField: "id" }
    );

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">Training Modules </h3>
                        <div>
                            <AddTrainingModulePopup />
                        </div>
                        {/* <hr className="mb-0" /> */}
                    </div>
                    <hr className="my-0" />
                </section>
                <section>
                    <div className="container py-4">
                        <div className="row">
                            {!loading &&
                                allTrainings &&
                                allTrainings.map((item) => (
                                    <TrainingCard data={item} />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewTrainingModules;

const TrainingCard = ({ data }) => {
    return (
        <div className="col-md-4 mb-3">
            {data && <div className="card training-module-card p-4">
                <img src={data.img} alt="" className="img-fluid mb-3" />
                <p className="font-bold mb-3">{data.name}</p>
                <p className="mb-3
                ">
                Grade Cap:{" "}
                {data.gradeCap &&
                    data.gradeCap.map((item) => (
                        <span className="btn btn-smaller btn-outline-primary me-2">
                            {item}
                        </span>
                    ))}
            </p>
            <p>Batch size: {data?.batchSize}</p>
            {/* <p>Training schedule:</p> */}
            {/* <div
                dangerouslySetInnerHTML={{ __html: data.trainingSchedule }}
            ></div> */}
                </div>}
            
        </div>
    );
};
