import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

const PopupCreatingEligibleList = ({ popupShow }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(popupShow);
    }, [popupShow]);

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                backdrop="static"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add Document -
                    </h5>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <div className="text-center">
                            <h3>Eligible List is being created!</h3>
                            <Spinner animation="grow"  />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupCreatingEligibleList;
