import React, { useEffect, useState } from "react";
import "react-data-grid/lib/styles.css";
// import DataGrid from "react-data-grid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import { Button } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import AdminNominationSummary from "./AdminNominationSummary";
import ReactDataGrid from "react-data-grid";
import CompFinalNominationConfirmation from "./CompFinalNominationConfirmation";

const AdminFinalGrid = ({ shortlistRef, trainingDetails }) => {
    const [editingRowId, setEditingRowId] = useState(null);

    const handleEditClick = (row) => {
        setEditingRowId(row.id);
    };

    const handleSaveClick = () => {
        // Save the edited row data here, e.g., update it in your API or state.
        setEditingRowId(null);
    };

    const handleCancelClick = () => {
        // Discard any changes and exit edit mode.
        setEditingRowId(null);
    };

    const [fetchedLists, loading, error] = useCollectionData(
        trainingParticipantListsCollectionRef
            .doc(shortlistRef)
            .collection(shortlistRef)
    );

    const [shortListed, setShortListed] = useState(null);

    useEffect(()=>{
        if (fetchedLists && fetchedLists.length >0) {
            const filteredNominations = fetchedLists.filter(item => item.nominated || item.waiting)
            setShortListed(filteredNominations)
        }
    },[fetchedLists])
    const [updatdShortList, setUpdatedShortList] = useState(null);

    const columns = [
        { key: "SFID", name: "SFID" },
        { key: "name", name: "Name" },
        { key: "payGrade", name: "Grade", sortable: true },
        { key: "department", name: "Function" },
        // {
        //     key: "priority",
        //     name: "Priority",
        //     minWidth: 100,
        //     renderCell: (params) => (
        //         <>
        //             <PrioritySelectorUnitHead
        //                 data={params.row}
        //                 docRef={shortlistRef}
        //             />
        //         </>
        //     ),
        // },
        {
            key: "status",
            name: "Status",
            minWidth: 35,
            renderCell: (params) => (
                <>
                    <NominationStatusComp data={params.row} />
                </>
            ),
        },
        {
            key: "action",
            name: "Action",
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <NominateIndividual
                        data={params.row}
                        docRef={shortlistRef}
                    />
                </>
            ),
        },
        {
            key: "dropout",
            name: "Dropout",
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <DropoutParticipant
                        data={params.row}
                        docRef={shortlistRef}
                    />
                </>
            ),
        },
        
        { key: "hrbpSF", name: "HRBP" },
        { key: "unitHeadName", name: "Unit Head" },
    ];

    const [filteredRows, setFilteredRows] = useState(shortListed);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    return (
        <>
            {shortListed && trainingDetails && (
                <AdminNominationSummary
                    participants={shortListed}
                    data={trainingDetails}
                />
            )}
            {shortListed && shortListed.length > 0 && (
                <ReactDataGrid
                    className="rdg-light"
                    height={900}
                    style={{ height: "450px" }}
                    columns={columns}
                    rows={shortListed.sort((a, b) => {
                        const nominatedA = a.nominated ? 1 : 0;
                        const nominatedB = b.nominated ? 1 : 0;
                        return nominatedB - nominatedA;
                    })}
                />
            )}
            {shortListed && trainingDetails && shortlistRef && (
                <CompFinalNominationConfirmation
                docRefID={shortlistRef}
                trainingDetails={trainingDetails}
                nominatedList={shortListed.filter(item => item.nominated === true)}
                />
            )}
        </>
    );
};

export default AdminFinalGrid;

const DropoutParticipant = ({data, docRef})=>{
    const [employeeStatus, setEmployeeStatus] = useState(null);
    useEffect(() => {
        if (data) {
            setEmployeeStatus(data);
        }
    }, [data]);

    const onDropout =()=>{
        trainingParticipantListsCollectionRef
        .doc(docRef)
        .collection(docRef)
        .doc(data.SFID)
        .update({
            dropout: true,
        });
    }
    
    return(
        <>
        {data && data.dropout ? <button className="btn btn-sm btn-danger"></button>:<button onClick={onDropout} className="btn btn-sm btn-outline-danger">Dropout</button>}
        </>
    )
}

const NominateIndividual = ({ data, docRef }) => {
    const [employeeStatus, setEmployeeStatus] = useState(null);
    useEffect(() => {
        if (data) {
            setEmployeeStatus(data);
        }
    }, [data]);
    const onNominationChange = (value) => {
        trainingParticipantListsCollectionRef
            .doc(docRef)
            .collection(docRef)
            .doc(employeeStatus.SFID)
            .update({
                nominated: value,
            });
    };
    return (
        <>
            {employeeStatus && (
                <ButtonGroup aria-label="Basic example">
                    {employeeStatus.nominated ? (
                        <button
                            onClick={() => onNominationChange(false)}
                            className="btn btn-smaller btn-outline-danger"
                        >
                            Remove
                        </button>
                    ) : (
                        <button
                            onClick={() => onNominationChange(true)}
                            className="btn btn-smaller btn-outline-success"
                        >
                            Nominate
                        </button>
                    )}
                </ButtonGroup>
            )}
        </>
    );
};
const NominationStatusComp = ({ data }) => {
    return (
        <>
            {data.nominated ? 
                <span className="btn btn-success btn-smaller">Nominated</span>: data.waiting &&
                <span className="btn btn-warning btn-smaller">Waiting List</span>
            }
        </>
    );
};
