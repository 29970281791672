import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { configurationDataRef } from "../../firebase/fire-app";
import CohortSetupPopup from "./CohortSetupPopup";

const ViewTrainingCohort = () => {
    const [currentCohort, setCurrentCohort] = useState("H1-2023");
    const  [webConfiguration, loading, error]  = useDocumentData(
        configurationDataRef.doc("webConfiguration")
    );
    useEffect(() => {
        if (!loading && webConfiguration) {
            setCurrentCohort(webConfiguration.currentCohort);
        }
    }, [webConfiguration, loading]);

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">Training Cohort</h3>
                        <div className="d-flex">
                            {currentCohort && <span className="btn btn-sm btn-primary">{currentCohort}</span> }
                            <CohortSetupPopup currentCohort={currentCohort} setCurrentCohort={setCurrentCohort} />
                        </div>
                        {/* <hr className="mb-0" /> */}
                    </div>
                    <hr className="my-0" />
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewTrainingCohort;
