import React, { useEffect, useState } from "react";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import AdminFinalGrid from "./AdminFinalCompletionGrid";

const AdminFinalTab = ({ cohort, trainingDetails }) => {
    const [eligibleListCreated, setEligibleListCreated] = useState(false);
    const [functionalSplit, setFunctionalSplit] = useState(null);


    return (
        <>
            <section>
                <div className="container-fluid">
                    {trainingDetails && (
                        <AdminFinalGrid
                            trainingDetails={trainingDetails}
                            shortlistRef={trainingDetails.docID}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default AdminFinalTab;
