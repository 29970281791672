import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

// import "@fontsource/montserrat/300.css";
// import "@fontsource/montserrat/400.css";
// import "@fontsource/montserrat/500.css";
// import "@fontsource/montserrat/700.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import { AuthContextProvider } from "./Hooks/useAuth";
import ViewTest from "./Views/ViewTest";
import ViewLogin from "./Views/LoginPage/ViewLogin";
import App from "./App";
import { SSOAuthContextProvider } from "./Hooks/useSSOAuth";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
                <SSOAuthContextProvider>
                    <App />
                </SSOAuthContextProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <ViewLogin />
            </UnauthenticatedTemplate>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
