import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import * as XLSX from "xlsx/xlsx.mjs";
import { excelDateToJsDateConverter } from "../../utilities/firebasetimestamp";
import {
    managementEmployeeCollectionRef,
    testCollectionRef,
} from "../../firebase/fire-app";
import SectionLoading from "../../Components/SectionLoading";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import firebase from "firebase";

const UploaderUnitHeadChange = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [massUploadList, setMassUploadList] = useState([]);

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheet_name_list = workbook.SheetNames;
            const xlData = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheet_name_list[0]]
            );
            setMassUploadList(xlData);
        };
        reader.readAsArrayBuffer(file);
    };
    const massUploadUnitHeadChange = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            if (doc.unitHead) {
                managementEmployeeCollectionRef.doc(doc.SFID).update({
                    unitHead: doc.unitHead,
                });
            }
        });
        setIsLoading(false);
    };

    return (
        <>
            <section className="py-4">
                <div className="container">
                    <div className="row">
                        <InputWrapper col="5" label="Unit Head">
                            <input
                                type="file"
                                name=""
                                className="form-control"
                                id=""
                                onChange={(e) =>
                                    handleFileUpload(e.target.files[0])
                                }
                            />
                        </InputWrapper>

                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                            <button className="btn btn-outline-primary">
                                DOWNLOAD TEMPLATE
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                onClick={massUploadUnitHeadChange}
                                className="btn-primary btn"
                            >
                                UPLOAD
                            </button>
                        </div>
                    </div>
                    <button
                        onClick={() => console.log(massUploadList)}
                        className="btn mt-4 btn-danger"
                    >
                        LOG
                    </button>
                </div>
            </section>
            {isLoading && <SectionLoading />}
        </>
    );
};

export default UploaderUnitHeadChange;
