import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmployeeTrainingStatusDataGrid from './EmployeeTrainingStatusDataGrid';
import { useSSOAuth } from '../../Hooks/useSSOAuth';

const PopupTrainingDetails = () => {
    const [show, setShow] = useState(false);
    const {  currentUser } = useSSOAuth();

    


    return (
        <>

            <Button variant="primary" size='sm' onClick={() => setShow(true)}>
                Training Details
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Add Document -
                    </h5>
                </Modal.Header> */}
                <Modal.Body>

                   <h4>Training Details</h4>
                   <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vel dicta quos qui corporis non natus consequuntur ad, provident delectus tenetur.</p>
                   <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus esse maiores mollitia a.</p>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupTrainingDetails;
