// import DashboardLayout from "./Components/DashboardLayout/DashboardLayout";
// import { AuthContextProvider } from "./Hooks/useAuth";
// import SidebarComponent from "./Components/Shared/SidebarComponent"
import "./styles/bootstrap.scss";
import ViewTest from "./Views/ViewTest";
import "./reset.scss";
import Main from "./Views/Main";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";
import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useSSOAuth } from "./Hooks/useSSOAuth";
import SectionLoading from "./Components/SectionLoading";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import {
    authUsersCollection,
    adminsCollectionRef,
    trainingModulesCollectionRef,
    managementEmployeeCollectionRef,
} from "./firebase/fire-app";
import WelcomePopup from "./Components/WelcomePopup";

function App() {
    const [authUsers, authUsersloading, authUserserror] = useDocumentData(
        authUsersCollection.doc("authUsers")
    );

    const {
        currentUser,
        setCurrentUser,
        hasAccess,
        setHasAccess,
        isAdmin,
        setIsAdmin,
        setIsUnitHead,
        isTalentManager,
        setIsTalentManager,
        setAlltrainings,
        setManagementList
    } = useSSOAuth();
    const [loading, setLoading] = useState(true);
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) =>
                    setGraphData(response)
                );
            });
    }
    useEffect(() => {
        if (isAuthenticated) {
            RequestProfileData();
        }
    }, [isAuthenticated]);
    const [mgtList, mgtLoading, mgtError] =useCollectionData(managementEmployeeCollectionRef, {idField: "id"})
    useEffect(()=>{
        if(mgtList && mgtList.length > 0){
            setManagementList(mgtList)
        }
    },[mgtLoading])
    useEffect(() => {
        if (graphData && isAuthenticated) {
            console.log("Graph data: ")
            console.log(graphData)
            // alert(JSON.stringify(graphData))
            const fetchedUserMail = graphData.mail
            const userEmail = fetchedUserMail.toLowerCase();
            if (authUsers) {
                setIsAdmin(authUsers.admins.includes(userEmail));
            }
      

            adminsCollectionRef
                .doc(userEmail)
                .get()
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        const userProfile = docSnapshot.data();
                        setCurrentUser(userProfile);
                    }else{
                        // alert('noooo')
                    }
                });
            setHasAccess(true);
        }
    }, [graphData]);

    const [fetchedTrainingModules, moduleLoading, moduleError] =
        useCollectionData(trainingModulesCollectionRef, { idField: "id" });

    useEffect(() => {
        if (fetchedTrainingModules && fetchedTrainingModules.length > 0) {
            setAlltrainings(fetchedTrainingModules);
        }
    }, [fetchedTrainingModules]);

    // final state setup
    useEffect(() => {
        if (currentUser && graphData && authUsers) {
            // alert(JSON.stringify(authUsers))
            const talentMangersArray = authUsers.talentManagers;
            setIsAdmin(authUsers.admins.includes(graphData.userPrincipalName));
            setIsUnitHead(
                authUsers.unitHeads.includes(graphData.userPrincipalName)
            );
            setIsTalentManager(
                authUsers.talentManagers.includes(graphData.userPrincipalName)
            );

            setLoading(false);
        }
    }, [currentUser, authUsers, graphData]);
    return (
        <>
            {/* <AuthContextProvider> */}
            {/* {currentUser && (
                <WelcomePopup popupShow={true} data={currentUser} />
            )} */}
            {loading ? <SectionLoading /> : <Main />}
            {/* </AuthContextProvider> */}
        </>
    );
}

export default App;
