import React, { useState, useContext, useEffect } from "react";


export const SSOAuthContext = React.createContext();
export const SSOAuthContextProvider = ({ children }) => {
    const authManager = SSOAuth();

    return (
        <SSOAuthContext.Provider value={authManager}>
            {children}
        </SSOAuthContext.Provider>
    );
};

export const useSSOAuth =()=> useContext(SSOAuthContext)


const SSOAuth = ()=>{
    const [currentUser, setCurrentUser] = useState(null);
    const [hasAccess, setHasAccess] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [userRole, setUserRole] = useState(null)
    const [isTalentManager, setIsTalentManager] = useState(null);
    const [isHRBP, setIsHRBP] = useState(null)
    const [isUnitHead, setIsUnitHead] = useState(null);
    const [allTrainings, setAlltrainings] = useState(null);
    const [managementList, setManagementList] = useState(null);


    return {currentUser,isHRBP,allTrainings,managementList, setManagementList, setAlltrainings, isUnitHead, setIsUnitHead, setIsHRBP, userRole, isTalentManager, setIsTalentManager, setUserRole, setCurrentUser, hasAccess, setHasAccess,isAdmin, setIsAdmin, showSidebar, setShowSidebar }
}