import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import {
    configurationDataRef,
    trainingModulesCollectionRef,
    trainingParticipantListsCollectionRef,
} from "../../firebase/fire-app";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import HRBPTab from "./HRBPTab";
import { useSSOAuth } from "../../Hooks/useSSOAuth";

const ViewHRBPPortal = () => {
    // training selection
    const [selectedTraining, setSelectedTraining] = useState(null);
    const [allTrainings, trainingLoading, trainingError] = useCollectionData(
        trainingParticipantListsCollectionRef.where("trainingPhaseStatus","==", "Eligible List"),
        { idField: "id" }
    );

    // cohort finalization
    const [currentCohort, setCurrentCohort] = useState("H1-2023");
    const {currentUser} = useSSOAuth()
    const [webConfiguration, loading, error] = useDocumentData(
        configurationDataRef.doc("webConfiguration")
    );
    useEffect(() => {
        if (!loading && webConfiguration) {
            setCurrentCohort(webConfiguration.currentCohort);
        }
    }, [webConfiguration, loading]);
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">
                            Eligible List - (HRBP Portal)
                        </h3>
                        {/* <div className="d-flex">
                            <div className="d-flex">
                                {selectedTraining && (
                                    <span className="btn btn-outline-primary">
                                        {selectedTraining.name}
                                    </span>
                                )}
                                {currentCohort && (
                                    <span className="btn btn-sm btn-primary">
                                        {currentCohort}
                                    </span>
                                )}
                            </div>
                        </div> */}
                        {/* <hr className="mb-0" /> */}
                    </div>
                    <hr className="my-0" />
                </section>
                <section>
                    <Tabs>
                        <TabList>
                            {allTrainings &&
                                allTrainings.length > 0 &&
                                allTrainings.map((item) => (
                                    <Tab>{item.shortName}</Tab>
                                ))}
                        </TabList>
                        {allTrainings && currentUser &&
                            allTrainings.length > 0 &&
                            allTrainings.map((item) => (
                                <TabPanel>
                                    <HRBPTab
                                        cohort={currentCohort}
                                        trainingDetails={item}
                                        key={item.id}
                                        hrbp={webConfiguration?.HRBPS}
                                        currentUser={currentUser}
                                    />
                                </TabPanel>
                            ))}
                    </Tabs>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewHRBPPortal;
