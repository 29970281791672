import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmployeeTrainingStatusDataGrid from './EmployeeTrainingStatusDataGrid';
import { useSSOAuth } from '../../Hooks/useSSOAuth';

const PopupEmployeeTrainingStatus = () => {
    const [show, setShow] = useState(false);
    const {  currentUser } = useSSOAuth();

    


    return (
        <>

            <Button variant="primary" size='sm' onClick={() => setShow(true)}>
                View Employee Training Status
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"

                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header className='py-1 ' closeButton>
                    <h5 className='font-weight-bold mt-1' id="example-custom-modal-styling-title">
                        Add Document -
                    </h5>
                </Modal.Header> */}
                <Modal.Body>

                    {currentUser &&<EmployeeTrainingStatusDataGrid userSFID={currentUser.SFID} />}

                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupEmployeeTrainingStatus;
