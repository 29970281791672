import React, { useEffect, useState } from "react";
import UnitHeadDataGrid from "./UnitHeadDataGrid";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import { useSSOAuth } from "../../Hooks/useSSOAuth";
import FunctionalSplitComp from "../ViewHRBPPortal/FunctionalSplitComp";
import PopupNominationConfirmed from "./PopupNominationConfirmed";
import PopupTrainingDetails from "./PopupTrainingDetails";

const UnitHeadTab = ({ cohort, unitHead, trainingDetails }) => {
    const [mySplitCount, setMySplitCount] = useState(null);

    const { currentUser } = useSSOAuth();
    const [eligibleListCreated, setEligibleListCreated] = useState(false);
    const [functionalSplit, setFunctionalSplit] = useState(null);

    const [eligibleListRef, setEligibleListRef] = useState(
        cohort + "-" + trainingDetails.id
    );
    const [nominatedArray, setNominatedArray] = useState([]);

    const [selectedTraining, setSelectedTraining] = useState(null);

    useEffect(() => {
        if (trainingDetails) {
            setSelectedTraining(trainingDetails);
        }
    }, [trainingDetails]);

    useEffect(() => {
        if (cohort && trainingDetails) {
            setEligibleListRef(`${cohort}-${trainingDetails?.id}`);
        }
    }, [cohort, trainingDetails]);
    const [mySplit, setMySplit] = useState(null);
    useEffect(() => {
        if ((unitHead, currentUser)) {
            setMySplit(currentUser.unit);
            // alert(JSON.stringify(hrbp));
            const myEmail = "shahjahan.swajan@myelinh.com";
            // const myFunction = findProperty(myEmail, unitHead);
            // setMySplit(myFunction);
        }
    }, [unitHead]);

    return (
        <>
            <section>
                <div className="container-fluid">
                    <TrainingDetailsForTabs
                        mySplitCount={mySplitCount}
                        data={trainingDetails}
                    />
                    {trainingDetails && currentUser && (
                        <UnitHeadDataGrid
                            shortlistRef={trainingDetails.id}
                            userSFID={currentUser.SFID}
                            splitDetails={trainingDetails.functionalSplit}
                            mySplit={mySplit}
                            updateMySplitCount={setMySplitCount}
                            setNominatedArray={setNominatedArray}
                        />
                    )}
                </div>
                {/* <div className="container-fluid mt-3 text-center">
                    {eligibleList && eligibleList.length > 0 && (
                        <button
                            onClick={onConfirmEligibleList}
                            className="btn-primary btn btn-s"
                        >
                            Confirm Eligible List
                        </button>
                    )}
                </div> */}
            </section>
        </>
    );
};

export default UnitHeadTab;

const TrainingDetailsForTabs = ({ data, mySplitCount }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {data && (
                <div className="card font-16 p-3 bg-light my-3">
                    <div className="d-flex justify-content-around ">
                        <p className="mb-0">
                            Training Name:{" "}
                            <span className="text-primary font-bold">
                                {data.name}
                            </span>
                        </p>

                        <p className="mb-0">
                            Eligible Grades:{" "}
                            {data.gradeCap.map((item) => (
                                <span className="font-bold text-primary">
                                    {item},{" "}
                                </span>
                            ))}
                        </p>
                        <PopupTrainingDetails/>
                    </div>
                </div>
            )}
            {data && (
                <div className="d-flex mb-3 justify-content-around">
                    <p className="text-center text-italic mt-2 mb-0">
                        You can nominate upto: <b>{mySplitCount} individuals</b>{" "}
                    </p>
                    <PopupNominationConfirmed />
                </div>
            )}
        </>
    );
};
