const dashboardMenu = [
   
    // {
    //     id: 1,
    //     text: `Training Modules`,
    //     href: `/training-modules`
    // },
    
]


export default dashboardMenu;