import React, { useEffect, useState } from "react";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import AdminFinalGrid from "./AdminFinalGrid";

const AdminFinalTab = ({ cohort, trainingDetails }) => {
    const [eligibleListCreated, setEligibleListCreated] = useState(false);
    const [functionalSplit, setFunctionalSplit] = useState(null);

    useEffect(()=>{
        if (cohort && trainingDetails) {
            // setEligibleListRef(`${cohort}-${trainingDetails?.id}`);
        }
        if (trainingDetails) {
            // setEligibleListRef(trainingDetails?.id);
        }
    },[cohort, trainingDetails])

    // useEffect(() => {
        // if (eligibleListRef) {
        //     trainingParticipantListsCollectionRef
        //         .doc(eligibleListRef)
        //         .get()
        //         .then((docSnapshot) => {
        //             if (docSnapshot.exists) {
        //                 const fetchedObj = docSnapshot.data();
        //                 setEligibleListCreated(fetchedObj);
        //                 setFunctionalSplit(fetchedObj.functionalSplit)
        //             }
        //         });
        // }
    // }, [eligibleListRef]);


    return (
        <>
            <section>
                <div className="container-fluid">
                    {trainingDetails && (
                        <AdminFinalGrid
                            trainingDetails={trainingDetails}
                            shortlistRef={trainingDetails.docID}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default AdminFinalTab;
