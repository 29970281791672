import firebase from "firebase";
import "firebase/auth";
import firebaseConfig from "../firebase-config";
import { webServerLive } from "./liveServerStatus";

// fbConfig = {
//     apiKey: "AIzaSyAnY7FO7_M5uEuak_L6oEU7TRZiwHFcIQc",
//     authDomain: "ec-myelinh.firebaseapp.com",
//     projectId: "ec-myelinh",
//     storageBucket: "ec-myelinh.appspot.com",
//     messagingSenderId: "310651316230",
//     appId: "1:310651316230:web:d67da11f86d7ad9335bb87",
//     measurementId: "G-9GJMP15Q9S",
// };

let fbConfig;
if (webServerLive) {
    fbConfig = {
        apiKey: "AIzaSyCFF_kmS5dJrCI154bkaXyk1lZipTu_ZGc",
        authDomain: "batb-training-module.firebaseapp.com",
        projectId: "batb-training-module",
        storageBucket: "batb-training-module.appspot.com",
        messagingSenderId: "1045255256554",
        appId: "1:1045255256554:web:4ba6b29fc5fbc9531ada93",
    };
} else {
    fbConfig = {
        apiKey: "AIzaSyCFF_kmS5dJrCI154bkaXyk1lZipTu_ZGc",
        authDomain: "batb-training-module.firebaseapp.com",
        projectId: "batb-training-module",
        storageBucket: "batb-training-module.appspot.com",
        messagingSenderId: "1045255256554",
        appId: "1:1045255256554:web:4ba6b29fc5fbc9531ada93",
    };
}

const fireApp = firebase.initializeApp(fbConfig);
fireApp
    .firestore()
    .enablePersistence()
    .catch((err) => {
        if (err.code === "failed-precondition") {
            console.log(err);
        } else console.log(err);
    });

export default fireApp;
export const fireStoreDB = fireApp.firestore();
export const fireDatabase = fireApp.database();
export const fireStorage = fireApp.storage();

export const authUsersCollection = fireStoreDB.collection("authUsers");


export const managementEmployeeCollectionRef = fireStoreDB.collection("managementList")
export const adminsCollectionRef = fireStoreDB.collection("admins")

export const trainingModulesCollectionRef = fireStoreDB.collection("trainingModules")
export const configurationDataRef = fireStoreDB.collection("configurationData")

export const trainingParticipantListsCollectionRef = fireStoreDB.collection("trainingParticipantLists")

export const testCollectionRef = fireStoreDB.collection("test")