import React, { useEffect, useState } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";
import {
    managementEmployeeCollectionRef,
    trainingParticipantListsCollectionRef,
} from "../../firebase/fire-app";
import { Button } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const EmployeeTrainingStatusDataGrid = ({ shortlistRef, userSFID }) => {
    const [editingRowId, setEditingRowId] = useState(null);

    const handleEditClick = (row) => {
        setEditingRowId(row.id);
    };

    const handleSaveClick = () => {
        // Save the edited row data here, e.g., update it in your API or state.
        setEditingRowId(null);
    };

    const handleCancelClick = () => {
        // Discard any changes and exit edit mode.
        setEditingRowId(null);
    };

    const [shortListed, loading, error] = useCollectionData(
        managementEmployeeCollectionRef.where("unitHeadSF", "==", userSFID)
    );
    const [updatdShortList, setUpdatedShortList] = useState(null);

    const columns = [
        { key: "SFID", name: "SFID" },
        { key: "name", name: "Name", resizable: true },
        { key: "payGrade", name: "Grade" },
        {
            key: "completedTrainings",
            name: "Trainings",
            width: 400,
            renderCell: (params) => <TrainingDetails data={params.row} />,
        },
    ];
    return (
        <>
            {shortListed && shortListed.length > 0 && (
                <DataGrid
                    className="rdg-light"
                    height={900}
                    style={{ height: "450px" }}
                    columns={columns}
                    rows={shortListed.sort((a, b) => {
                        return a.priority - b.priority;
                    })}
                />
            )}
        </>
    );
};

export default EmployeeTrainingStatusDataGrid;

const TrainingDetails = ({ data }) => {
    const [allTrainings, setAllTrainings] = useState(null);
    useEffect(() => {
        if (data) {
            setAllTrainings(data.completedTrainings);
            console.log(data.completedTrainings);
        } else {
            setAllTrainings(["-"]);
        }
    }, [data]);

    return (
        <>
            {allTrainings &&
                allTrainings.length > 0 &&
                allTrainings.map((item) => <TrainingName id={item} />)}
        </>
    );
};

const TrainingName = ({id}) => {
    const [trainingData, loading, error ] = useDocumentData(
        trainingParticipantListsCollectionRef.doc(id)
    );
    return (
        <>
            {trainingData && <span className="px-3 py-1 bg-light mx-2">
                {trainingData?.shortName}
            </span>}
        </>
    );
};
