import React, { useEffect, useState } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Button } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { managementEmployeeCollectionRef } from "../../firebase/fire-app";

const ManagementListDataGrid = () => {
    const [managementList, loading, error] = useCollectionData(
        managementEmployeeCollectionRef,
        { idField: "id" }
    );

    const columns = [
        
        { key: "SFID", name: "SFID", maxWidth: 100, resizable:true },
        { key: "name", name: "Name", resizable: true },
        { key: "designation", name: "Designation", resizable: true },
        { key: "department", name: "Function", resizable: true },
        { key: "payGrade", name: "Grade", maxWidth: 80, resizable: true },
        { key: "hrbpName", name: "HRBP", resizable: true },
        { key: "unitHeadName", name: "unitHead", resizable: true },
    ];
    return (
        <>
            {managementList && managementList.length > 0 && (
                <DataGrid
                    className="rdg-light"
                    style={{ height: "900px" }}
                    columns={columns}
                    rows={managementList}
                />
            )}
        </>
    );
};

export default ManagementListDataGrid;


const ClickButton =({data})=>{

    return(

        <>
        <button onClick={()=>alert(JSON.stringify(data))} className="btn-primary btn-sm btn">log</button>
        </>
    )
}