import React, { useEffect, useState } from "react";
import {
    excelDateToJSDate,
    excelDateToJsDateConverter,
} from "../../utilities/firebasetimestamp";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { configurationDataRef } from "../../firebase/fire-app";

const AdminNominationSummary = ({ data, participants }) => {
    const [nominationList, setNominationList] = useState(null);
    const [show, setShow] = useState(false);
    const [hrbpList, setHrbpList] = useState(null)
    const [corpNominationCount, setCorpNominationCount] = useState(null);
    const [leafNominationCount, setLeafNominationCount] = useState(null);
    const [mktNominationCount, setMktNominationCount] = useState(null);
    const [opsNominationCount, setOpsNominationCount] = useState(null);

    const [webConfiguration, loading, error] = useDocumentData(
        configurationDataRef.doc("webConfiguration")
    );

    useEffect(()=>{
        if (webConfiguration) {
            setHrbpList(webConfiguration.HRBPS)
        }
    },[webConfiguration])

    useEffect(() => {
        if (nominationList && nominationList.length > 0 && hrbpList) {
            const corp = nominationList.filter(
                (item) => item.hrbpSF === hrbpList.Corporate
            );
            const leaf = nominationList.filter(
                (item) => item.hrbpSF === hrbpList.Leaf
            );
            const mkt = nominationList.filter(  
                (item) => item.hrbpSF === hrbpList.MarketingCommercial
            );
            const ops = nominationList.filter(
                (item) => item.hrbpSF === hrbpList.Operations
            );
            setCorpNominationCount(corp.length);
            setLeafNominationCount(leaf.length);
            setMktNominationCount(mkt.length);
            setOpsNominationCount(ops.length);
        }
    }, [nominationList, hrbpList]);

    useEffect(() => {
        if (participants && participants.length > 0) {
            const finalNom = participants.filter(
                (item) => item.nominated === true
            );
            setNominationList([...finalNom]);
        }
    }, [participants]);

    return (
        <>
            {data && (
                <div className="card font-16 p-3 bg-light my-3">
                    <div className="d-flex justify-content-between  ">
                        <p className="mb-0">
                            Training Name:{" "}
                            <span className="text-primary font-bold">
                                {data.name}
                            </span>
                        </p>

                        <p>
                            Eligible Grades:{" "}
                            {data.gradeCap.map((item) => (
                                <span className="font-bold text-primary">
                                    {item},{" "}
                                </span>
                            ))}
                        </p>
                    </div>

                    <div className="d-flex mb-3 justify-content-between  font-bold  ">
                        <p className="mb-0">
                            Short name:{" "}
                            <span className="text-primary font-bold">
                                {data.shortName}
                            </span>
                        </p>
                        <p className="mb-0">
                            Batch size:{" "}
                            <span className="text-primary font-bold">
                                {data.batchSize}
                            </span>
                        </p>
                        <p className="mb-0">
                            Start at:{" "}
                            <span className="text-primary font-bld">
                                {excelDateToJSDate(data.startDate)} {"   "}
                            </span>
                        </p>
                        <p className="mb-0">
                            End at:{" "}
                            <span className="text-primary font-bld">
                                {excelDateToJSDate(data.endDate)} {"   "}
                            </span>
                        </p>
                    </div>
                    <div className="d-flex justify-content-between  font-bold  ">
                        <p className="mb-0">Nominated: {corpNominationCount + mktNominationCount+opsNominationCount+leafNominationCount}</p>
                        <p className="mb-0">
                            Corporate:{" "}
                            <span className="text-primary font-bld">
                                {corpNominationCount} {"   "}
                            </span>
                        </p>

                        <p className="mb-0">
                            Marketing & Commercial:{" "}
                            <span className="text-primary font-bld">
                                {mktNominationCount}
                            </span>
                        </p>
                        <p className="mb-0">
                            Operations:{" "}
                            <span className="text-primary font-bld">
                                {opsNominationCount}
                            </span>
                        </p>
                        <p className="mb-0">
                            Leaf:{" "}
                            <span className="text-primary font-bld">
                                {leafNominationCount}
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminNominationSummary;
