// import { useLocation, Link } from "react-router-dom";
import "firebase/auth";
import GoogleIcon from "./google.png";
import "./LoginPage.scss";
import { useForm } from "react-hook-form";
import { useDocumentData } from "react-firebase-hooks/firestore";
import PasswordResetPopup from "./PasswordResetPopup";
import { webServerLive } from "../../firebase/liveServerStatus";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "../../SSOComponents/SignOutButton";
import { SignInButton } from "../../SSOComponents/SignInButton";

const ViewLogin = () => {
    // let navigate = useNavigate();
    // let location = useLocation();
    // let { from } = location.state || { from: { pathname: "/" } };

   

    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <section
                className={webServerLive ? "bg-primary py-3" : "bg-info py-3"}
            >
                <div className="container text-white text-white mb-0">
                    <h3>BATB HR Employee Center</h3>
                </div>
            </section>
            <section>
                <div className="container mt-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            <div className="py-4 pb-5 px-5 login-layout text-center">
                                <h2 className="text-center">
                                    Login
                                </h2>
                                {isAuthenticated ? (
                                    <SignOutButton />
                                ) : (
                                    <SignInButton />
                                )}

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ViewLogin;
