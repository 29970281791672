import React from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";



const rows = [
    { id: 0, title: "Example" },
    { id: 1, title: "Demo" },
];

const EligibleListViewerDataGrid = ({ managementList,onRemoveListItem }) => {
    const columns = [
        { key: "SFID", name: "SFID" },
        { key: "name", name: "Name" },
        { key: "payGrade", name: "Grade" },
        { key: "department", name: "Function" },
        {
            key: "action",
            name: "Action",
            minWidth: 25,
            renderCell: (params) => (
                <>
                    <ItemRemoverDataGrid
                        data={params.row}
                        onRemoveListItem={onRemoveListItem}
                    />
                </>
            ),
        },
        { key: "hrbpName", name: "HRBP" },
        { key: "unitHeadName", name: "Unit Head" },
    ];
    return (
        <>
        {/* {
          managementList && managementList.length>0 &&  <p className="mb-0">Total: {managementList.length}</p>
        } */}
            {managementList && managementList.length > 0 && (
                <DataGrid
                    className="rdg-light"
                    columns={columns}
                    rows={managementList}
                />
            )}
        </>
    );
};

export default EligibleListViewerDataGrid;

const ItemRemoverDataGrid =({data,onRemoveListItem})=>{
    
    return(
        <>
        <button onClick={()=>onRemoveListItem(data)} className="btn btn-smaller btn-outline-danger">Remove</button>
        </>
    )
}