import React, { useEffect, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill styles
import { trainingModulesCollectionRef } from "../../firebase/fire-app";
import { managementGrades } from "../../utilities/staticData";

const AddTrainingModulePopup = () => {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, watch, errors, getValues } = useForm();
    const [logoImg, setLogoImg] = useState(null);

    const [richContent, setRichContent] = useState("");

    const handleRichContentChange = (value) => {
        setRichContent(value);
    };
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            console.log(reader.result);
            setLogoImg(reader.result);
        };

        reader.readAsDataURL(file);
    }, []);
    const onSubmit = (data, e) => {
        const checkedOptions = checkboxOptions.filter(
            (option) => data[option.value]
        );

        data.gradeCap = checkedOptions.map((item) => item.value);
        data.createAt = new Date();
        const trainingDetailsData = {
            createAt: data.createAt,
            name: data.name,
            gradeCap: data.gradeCap,
            shortName: data.shortName,
            batchSize: Number(data.batchSize),
            trainingSchedule: richContent,
            img: logoImg ? logoImg : "",
        };
        trainingModulesCollectionRef
            .doc(data.shortName)
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.exists) {
                    alert(
                        "Training already exists! Please add with a different short name."
                    );
                } else {
                    trainingModulesCollectionRef
                        .doc(data.shortName)
                        .set(trainingDetailsData)
                        .then(() => {
                            setShow(false);
                        });
                }
            });
    };
    const [selectedOptions, setSelectedOptions] = useState([]);

    const checkboxOptions = managementGrades;

    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                Add Training Module
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add Training Module
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmit)} className="row">
                            <InputWrapper col="7" label="name">
                                <input
                                    type="text"
                                    {...register("name")}
                                    name="name"
                                    class="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper col="5" label="Short Name">
                                <input
                                    type="text"
                                    {...register("shortName")}
                                    name="shortName"
                                    class="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper col="3" label="Batch Size">
                                <input
                                    type="number"
                                    {...register("batchSize")}
                                    name="batchSize"
                                    class="form-control"
                                />
                            </InputWrapper>
                            <div className="col-md-9 d-flex">
                                Eligible Grades:
                                {checkboxOptions.map((option) => (
                                    <div className="ms-3" key={option.value}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={option.value}
                                                {...register(option.value)}
                                            />
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="col-md-9">
                                <p className="font-bold mb-0">
                                    Training Description:
                                </p>
                                <ReactQuill
                                    value={richContent}
                                    onChange={handleRichContentChange}
                                    modules={AddTrainingModulePopup.modules}
                                    formats={AddTrainingModulePopup.formats}
                                    placeholder="Write something..."
                                />
                            </div>
                            <InputWrapper col="7" addClass="mt-3" label="Logo">
                                <input
                                    type="file"
                                    onChange={(e) => onDrop(e.target.files)}
                                    name=""
                                    className="form-control"
                                    id=""
                                />
                            </InputWrapper>
                            {logoImg && (
                                <div className="col-md-5">
                                    <img
                                        src={logoImg}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            )}
                            <div className="col-12 mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Add Module
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

AddTrainingModulePopup.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

AddTrainingModulePopup.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
];

export default AddTrainingModulePopup;
