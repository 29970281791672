const unitHeadMenus = [
    
    {
        id: 4,
        text: `Unit Head Portal`,
        href: `/shortlist-nominations`,
    },

   
];

const hrbpMenus = [
    {
        id:5,
        text: `HRBP Portal`,
        href: `/hrbp-portal`
    }
]

export {unitHeadMenus, hrbpMenus}