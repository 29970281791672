const adminSidebarMenus = [
    {
        id: 1,
        text: `Create Eligible List`,
        href: `/create-eligible-list`,
    },
    {
        id: 2,
        text: `Ongoing Nominations`,
        href: `/admin/ongoing-nomination-list`,
    },
    {
        id: 3,
        text: `Completed Trainings`,
        href: `/admin/completed-trainings`,
    },
    {
        id: 4,
        text: `Training Modules`,
        href: `/training-modules`,
    },
    {
        id: 5,
        text: `Management List`,
        href: `/admin/management-list`,
    },
    {
        id: 6,
        text: `Mass Upload`,
        href: `/admin/mass-upload`,
    },
    {
        id: 6,
        text: `Access Control`,
        href: `/admin/access-control`,
    },
];

export default adminSidebarMenus;
