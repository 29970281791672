import React from 'react';
import { managementEmployeeCollectionRef, trainingParticipantListsCollectionRef } from '../../firebase/fire-app';
import firebase from "firebase";

const CompFinalNominationConfirmation = ({docRefID,trainingDetails, nominatedList}) => {
    
    const onFinalizationList =()=>{
        if(nominatedList && trainingDetails){
            if(nominatedList.length === trainingDetails.batchSize){
                alert(JSON.stringify(nominatedList[0]))
                trainingParticipantListsCollectionRef.doc(docRefID).update({
                    trainingPhaseStatus: "Final"
                })
                nominatedList.forEach((employee)=>{
                    managementEmployeeCollectionRef.doc(employee.SFID).update({
                        completedTrainings:
                        firebase.firestore.FieldValue.arrayUnion(
                            trainingDetails.id
                        ), 
                    })
                })
                // alert(JSON.stringify(nominatedList))
                // alert("Finallll")
                
            }else{
                alert(JSON.stringify(nominatedList.length))

                alert("Total nominations doesn't match batch size.")
            }
        }
    }
    
    return (
        <>
            <div className="py-3 text-center">
                <button onClick={onFinalizationList} className="btn btn-primary">Finalize Nomination List</button>
            </div>
        </>
    );
};

export default CompFinalNominationConfirmation;