import React from "react";
import TrainingDetailsForTabs from "./TrainingDetailsForTabs";
import TrainingDetailsPopup from "./TrainingDetailsPopup";

const FunctionalSplitComp = ({ data, mySplit, nominatedArray,trainingDetails }) => {
    return (
        <div className="card font-16 font-bold p-3 bg-light my-3">
            {data && (
                <div className="d-flex justify-content-around">
                    {/* <p className="mb-0">Corporate: {data.Corporate}</p>
                    <p className="mb-0">
                        Marketing & Commercial: {data.MarketingCommercial}
                    </p>
                    <p className="mb-0">Operations: {data.Operations}</p>
                    <p className="mb-0">Leaf: {data.Leaf}</p> */}
                   {mySplit && <p className="mb-0">
                        {mySplit}: {data[mySplit]}
                    </p>}
                    {nominatedArray && <p className="mb-0">Nominated: {nominatedArray?.length}</p>}
                    {data && <TrainingDetailsPopup data={trainingDetails} />}
                </div>
            )}
        </div>
    );
};

export default FunctionalSplitComp;
