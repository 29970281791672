import React, { useEffect, useState } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import { Button } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const UnitHeadDataGrid = ({ shortlistRef,splitDetails, setNominatedArray,updateMySplitCount, mySplit, userSFID }) => {
    const [editingRowId, setEditingRowId] = useState(null);

    const [nominatedCount, setNominatedCount] = useState(null);
    const [mySplitCount, setMySplitCount] = useState(null)
    const [waitingMode, setWaitingMode] = useState(null);
    const [shortListed, loading, error] = useCollectionData(
        trainingParticipantListsCollectionRef
            .doc(shortlistRef)
            .collection(shortlistRef)
            .where("unitHeadSF", "==", userSFID)
    );
    useEffect(() => {
        if (shortListed && shortListed.length > 0) {
            const nominatedList = shortListed.filter(
                (item) => item.nominated === true
            );
            // alert(JSON.stringify(nominatedList.length));
            setNominatedArray([...nominatedList]);
            setNominatedCount(nominatedList.length);
        }
    }, [shortListed]);
    useEffect(()=>{
        if(mySplit && splitDetails){
            const ownSplit = splitDetails[mySplit]
            setMySplitCount(ownSplit)
            updateMySplitCount(ownSplit)
        }
    },[splitDetails, mySplit])
    useEffect(() => {
        if (nominatedCount && mySplitCount) {
            if (nominatedCount === mySplitCount || nominatedCount > mySplitCount) {
                setWaitingMode(true);
            } else {
                setWaitingMode(false);
            }
        }
    }, [nominatedCount, mySplitCount]);

    const handleEditClick = (row) => {
        setEditingRowId(row.id);
    };

    const handleSaveClick = () => {
        // Save the edited row data here, e.g., update it in your API or state.
        setEditingRowId(null);
    };

    const handleCancelClick = () => {
        // Discard any changes and exit edit mode.
        setEditingRowId(null);
    };

    useEffect(() => {
        if (nominatedCount && mySplitCount) {
            if (nominatedCount === mySplitCount || nominatedCount > mySplitCount) {
                setWaitingMode(true);
            } else {
                setWaitingMode(false);
            }
        }
    }, [nominatedCount, mySplitCount]);
    
    const [updatdShortList, setUpdatedShortList] = useState(null);

    const columns = [
        { key: "SFID", name: "SFID" },
        { key: "name", name: "Name", resizable: true },
        { key: "payGrade", name: "Grade" },
        { key: "department", name: "Function" },

        // {
        //     key: "priority",
        //     name: "Priority",
        //     width: 280,
        //     renderCell: (params) => (
        //         <>
        //             <PrioritySelectorUnitHead
        //                 data={params.row}
        //                 docRef={shortlistRef}
        //                 shortListed={shortListed}
        //             />
        //         </>
        //     ),
        // },
        {
            key: "status",
            name: "Status",
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <NominationStatusComp
                        data={params.row}
                        docRef={shortlistRef}
                    />
                </>
            ),
        },
        {
            key: "action",
            name: "Action",
            minWidth: 150,
            renderCell: (params) => (
                <>
                    <NominateIndividual
                        data={params.row}
                        docRef={shortlistRef}
                        waitingMode={waitingMode}
                    />
                </>
            ),
        },
        { key: "hrbpName", name: "HRBP" },
        { key: "unitHeadName", name: "Unit Head" },
    ];
    return (
        <>
            {shortListed && shortListed.length > 0 && (
                <DataGrid

                    className="rdg-light"
                    height={900}
                    style={{ height: "450px" }}
                    columns={columns}
                    rows={shortListed.sort((a, b) => {
                        return a.priority - b.priority;
                    })}
                />
            )}
        </>
    );
};

export default UnitHeadDataGrid;
const NominationStatusComp = ({ data, docRef }) => {
    return (
        <>
            {data.nominated ? (
                <span className="btn btn-success btn-smaller">Nominated</span>
            ) : data.waiting ? (
                <span className="btn btn-warning btn-smaller">
                    Waiting List
                </span>
            ) : (
                ""
            )}
        </>
    );
};

const NominateIndividual = ({ data, docRef, waitingMode }) => {
    const [employeeStatus, setEmployeeStatus] = useState(null);
    useEffect(() => {
        if (data) {
            setEmployeeStatus(data);
        }
    }, [data]);
    const onNominationChange = (value) => {
        if (value==="nominate") {
            trainingParticipantListsCollectionRef
                .doc(docRef)
                .collection(docRef)
                .doc(employeeStatus.SFID)
                .update({
                    nominated: true,
                    waiting: false,
                });
        }else if(value === "waiting"){
            trainingParticipantListsCollectionRef
            .doc(docRef)
            .collection(docRef)
            .doc(employeeStatus.SFID)
            .update({
                nominated: false,
                waiting: true,
            });
        }
         else {
            trainingParticipantListsCollectionRef
                .doc(docRef)
                .collection(docRef)
                .doc(employeeStatus.SFID)
                .update({
                    nominated: false,
                    waiting: false
                });
        }
    };

    return (
        <>
            {employeeStatus && (
                <ButtonGroup aria-label="Basic example">
                    {employeeStatus.nominated || employeeStatus.waiting ? (
                        <button
                            onClick={() => onNominationChange("remove")}
                            className="btn btn-smaller btn-danger"
                        >
                            Remove
                        </button>
                    ) : waitingMode ? (
                        <button
                            onClick={() => onNominationChange("waiting")}
                            className="btn btn-smaller btn-outline-warning"
                        >
                           Add to Waiting List
                        </button>
                    ) : (
                        <button
                            onClick={() => onNominationChange("nominate")}
                            className="btn btn-smaller btn-outline-primary"
                        >
                            Nominate
                        </button>
                    )}
                </ButtonGroup>
            )}
        </>
    );
};

const PrioritySelectorUnitHead = ({ data, docRef, shortListed }) => {
    const [employeeStatus, setEmployeeStatus] = useState(null);
    useEffect(() => {
        if (data) {
            setEmployeeStatus(data);
        }
    }, [data]);
    const onChangePriority = (priorityValue) => {
        const previousSamePriority = shortListed.filter(
            (item) => item.priority === priorityValue
        );

        if (previousSamePriority.length > 0) {
            alert(`Priority ${priorityValue} assigned to ${previousSamePriority[0].name}!`);
        } else {
            trainingParticipantListsCollectionRef
                .doc(docRef)
                .collection(docRef)
                .doc(employeeStatus.SFID)
                .update({
                    priority: Number(priorityValue),
                });
        }
    };
    return (
        <>
            {employeeStatus && (
                <ButtonGroup aria-label="Basic example">
                    <Button
                        onClick={() => onChangePriority(1)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 1 ? "primary" : "light"
                        }`}
                    >
                        1
                    </Button>
                    <Button
                        onClick={() => onChangePriority(2)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 2 ? "primary" : "light"
                        }`}
                    >
                        2
                    </Button>
                    <Button
                        onClick={() => onChangePriority(3)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 3 ? "primary" : "light"
                        }`}
                    >
                        3
                    </Button>
                    <Button
                        onClick={() => onChangePriority(4)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 4 ? "primary" : "light"
                        }`}
                    >
                        4
                    </Button>
                    <Button
                        onClick={() => onChangePriority(5)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 5 ? "primary" : "light"
                        }`}
                    >
                        5
                    </Button>
                    <Button
                        onClick={() => onChangePriority(6)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 6 ? "primary" : "light"
                        }`}
                    >
                        6
                    </Button>
                    <Button
                        onClick={() => onChangePriority(7)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 7 ? "primary" : "light"
                        }`}
                    >
                        7
                    </Button>
                    <Button
                        onClick={() => onChangePriority(8)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 8 ? "primary" : "light"
                        }`}
                    >
                        8
                    </Button>
                    <Button
                        onClick={() => onChangePriority(9)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 9 ? "primary" : "light"
                        }`}
                    >
                        9
                    </Button>
                    <Button
                        onClick={() => onChangePriority(10)}
                        size="sm"
                        variant={`${
                            employeeStatus.priority === 10 ? "primary" : "light"
                        }`}
                    >
                        10
                    </Button>
                </ButtonGroup>
            )}
        </>
    );
};
