import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";

const FunctionalSplitPopup = ({
    setFunctionalSplit,
    functionalSplit,
    batchSize,
    addClass,
    eligibleListRef,
    setBatchSizeLocal,
}) => {
    const { register, handleSubmit, watch, errors, getValues } = useForm();
    const [funcSplit, setFuncSplit] = useState({
        HR: 0,
        LEX: 0,
        FINANCE: 0,
        IDT: 0,
        MARKETING: 0,
        COMMERCIAL: 0,
        QUALITY: 0,
        PROCUREMENT: 0,
        PRODUCT: 0,
        ADMIN: 0,
        LEAF: 0,
    });
    const allUnits = ["HR", "LEX", "FINANCE", "IDT", "MARKETING", "COMMERCIAL", "QUALITY", "PROCUREMENT", "PRODUCT", "ADMIN", "LEAF"]
    const onFunctionalSplitUpdate = (data, e) => {
        const splitData = {
            
        };
        allUnits.forEach(unit => {
            if(data.hasOwnProperty(unit)){
                splitData[unit] = Number(data[unit])
            }
        })
        const assignedBatches = Object.values(splitData).reduce((acc, value) => acc + value, 0);


        console.log(batchSize);
        if (Number(batchSize) !== Number(assignedBatches)) {
            if (Number(batchSize) > assignedBatches) {
                alert("Please add more to a function!");
            } else {
                console.log(assignedBatches);
            }
        } else {
            setFunctionalSplit(splitData);
        }
    };
    const onSplitFieldChange = (value, field) => {
        if (value && field) {
            console.log(value, field);
            setFuncSplit({ ...funcSplit, [field]: Number(value) });
            console.log(funcSplit);
            setFunctionalSplit({ ...funcSplit, [field]: Number(value) });
        }
    };
    // useEffect(() => {
    //     if (funcSplit) {
    //         setFunctionalSplit({ ...funcSplit });
    //     }
    // }, [funcSplit]);

    const onBatchSizeChange = (value) => {
        setBatchSizeLocal(value);
    };
    // useEffect(() => {
    //     setBatchSizeLocal(5);
    // }, []);
    return (
        <>
            {functionalSplit ? (
                <form
                    onSubmit={handleSubmit(onFunctionalSplitUpdate)}
                    className={`row d-flex align-items-center mb-3 ${addClass}`}
                >
                    {/* <div className="col-md-2">
                <p className="font-bold mb-0">Function Split:</p>
            </div> */}
                    <div className="col-12 mb-3">
                        <div className="row">
                            <InputWrapper
                                mb0
                                col="3"
                                label={`Batch size (${batchSize})`}
                            >
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={batchSize}
                                    onBlur={(e) =>
                                        onBatchSizeChange(e.target.value)
                                    }
                                />
                            </InputWrapper>
                        </div>
                    </div>

                    {/* corporate */}
                    <div className="row mb-3">
                            <InputWrapper mb0 col="2" label="HR">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(e.target.value, "HR")
                                    }
                                    type="number"
                                    name="HR"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="FINANCE">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "FINANCE"
                                        )
                                    }
                                    type="number"
                                    name="FINANCE"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="LEX">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "LEX"
                                        )
                                    }
                                    type="number"
                                    name="LEX"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="IDT">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "IDT"
                                        )
                                    }
                                    type="number"
                                    name="IDT"
                                    className="form-control"
                                />
                            </InputWrapper>
                        </div>
                        <div className="row mb-3">
                            <InputWrapper mb0 col="3" label="MARKETING">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "MARKETING"
                                        )
                                    }
                                    type="number"
                                    name="MARKETING"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="3" label="COMMERCIAL">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "COMMERCIAL"
                                        )
                                    }
                                    type="number"
                                    name="COMMERCIAL"
                                    className="form-control"
                                />
                            </InputWrapper>
                        </div>
                        <div className="row mb-3">
                            <InputWrapper mb0 col="3" label="QUALITY">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "QUALITY"
                                        )
                                    }
                                    type="number"
                                    name="QUALITY"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="3" label="PROCUREMENT">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "PROCUREMENT"
                                        )
                                    }
                                    type="number"
                                    name="PROCUREMENT"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="3" label="PRODUCT">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "PRODUCT"
                                        )
                                    }
                                    type="number"
                                    name="PRODUCT"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="ADMIN">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "ADMIN"
                                        )
                                    }
                                    type="number"
                                    name="ADMIN"
                                    className="form-control"
                                />
                            </InputWrapper>
                        </div>
                        <div className="row mb-3">
                            <InputWrapper mb0 col="3" label="LEAF">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "LEAF"
                                        )
                                    }
                                    type="number"
                                    name="LEAF"
                                    className="form-control"
                                />
                            </InputWrapper>
                            
                        </div>
                    {/* <div className="col-md-2">
                        <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                        >
                            Update Split
                        </button>
                    </div> */}
                </form>
            ) : (
                <form
                    onSubmit={handleSubmit(onFunctionalSplitUpdate)}
                    className={`row d-flex align-items-center mb-3 ${addClass}`}
                >
                    {/* <div className="col-md-2">
                    <p className="font-bold mb-0">Function Split:</p>
                </div> */}

                    <div className="col-12 mb-3">
                        <div className="row">
                            <InputWrapper
                                mb0
                                col="3"
                                label={`Batch size (${batchSize})`}
                            >
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={batchSize}
                                    onBlur={(e) =>
                                        onBatchSizeChange(e.target.value)
                                    }
                                />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row mb-3">
                            <InputWrapper mb0 col="2" label="HR">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(e.target.value, "HR")
                                    }
                                    type="number"
                                    name="HR"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="FINANCE">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "FINANCE"
                                        )
                                    }
                                    type="number"
                                    name="FINANCE"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="LEX">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "LEX"
                                        )
                                    }
                                    type="number"
                                    name="LEX"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="IDT">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "IDT"
                                        )
                                    }
                                    type="number"
                                    name="IDT"
                                    className="form-control"
                                />
                            </InputWrapper>
                        </div>
                        <div className="row mb-3">
                            <InputWrapper mb0 col="2" label="MARKETING">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "MARKETING"
                                        )
                                    }
                                    type="number"
                                    name="MARKETING"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="COMMERCIAL">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "COMMERCIAL"
                                        )
                                    }
                                    type="number"
                                    name="COMMERCIAL"
                                    className="form-control"
                                />
                            </InputWrapper>
                        </div>
                        <div className="row mb-3">
                            <InputWrapper mb0 col="3" label="QUALITY">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "QUALITY"
                                        )
                                    }
                                    type="number"
                                    name="QUALITY"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="3" label="PROCUREMENT">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "PROCUREMENT"
                                        )
                                    }
                                    type="number"
                                    name="PROCUREMENT"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="3" label="PRODUCT">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "PRODUCT"
                                        )
                                    }
                                    type="number"
                                    name="PRODUCT"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper mb0 col="2" label="ADMIN">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "ADMIN"
                                        )
                                    }
                                    type="number"
                                    name="ADMIN"
                                    className="form-control"
                                />
                            </InputWrapper>
                        </div>
                        <div className="row mb-3">
                            <InputWrapper mb0 col="3" label="LEAF">
                                <input
                                    onBlur={(e) =>
                                        onSplitFieldChange(
                                            e.target.value,
                                            "LEAF"
                                        )
                                    }
                                    type="number"
                                    name="LEAF"
                                    className="form-control"
                                />
                            </InputWrapper>
                            
                        </div>
                    </div>

                    
                    {/* <div className="col-md-2">
                        <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                        >
                            Update Split
                        </button>
                    </div> */}
                </form>
            )}
        </>
    );
};

export default FunctionalSplitPopup;

const InputWrapper = ({ col, label, addClass, children, mb0 }) => {
    return (
        <div className={"mb-0 col-md-" + col + " " + addClass}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">{label} </span>
                </div>
                {children}
            </div>
        </div>
    );
};
