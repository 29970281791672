import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";

const ViewTrainingCalendar = () => {
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center ">
                        <h3 className="mb-0">Training Calendar - 2024 </h3>
                        {/* <hr className="mb-0" /> */}
                    </div>
                    <hr className="my-0" />
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewTrainingCalendar;
