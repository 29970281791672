// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAnY7FO7_M5uEuak_L6oEU7TRZiwHFcIQc",
    authDomain: "ec-myelinh.firebaseapp.com",
    projectId: "ec-myelinh",
    storageBucket: "ec-myelinh.appspot.com",
    messagingSenderId: "310651316230",
    appId: "1:310651316230:web:d67da11f86d7ad9335bb87",
    measurementId: "G-9GJMP15Q9S"
  };
  

export default firebaseConfig;