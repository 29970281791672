import React from 'react';
import { Spinner } from 'react-bootstrap';

const SectionLoading = () => {
    return (
        <>
            <section>
                <div className="d-flex justify-content-center align-items-center loader-container">
                    <div className="">
                        <Spinner animation="border" variant="primary" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionLoading;