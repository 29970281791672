import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "../SSOComponents/SignOutButton";
import { SignInButton } from "../SSOComponents/SignInButton";
import { ProfileData } from "../SSOComponents/ProfileData";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { callMsGraph } from "../graph";

const ViewTest = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) =>
                    setGraphData(response)
                );
            });
    }
    useEffect(() => {
        if (isAuthenticated) {
            RequestProfileData();
        }
    }, [isAuthenticated]);
    return (
        <div>
            <h3> hiiiiii</h3>
            {graphData &&<ProfileData graphData={graphData} />}
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </div>
    );
};

export default ViewTest;
