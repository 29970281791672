import React, { useEffect, useState } from "react";
import SectionLoading from "../Components/SectionLoading";
import * as XLSX from "xlsx/xlsx.mjs";
import * as FileSaver from "file-saver";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    bankAccountCollectionRef,
    claimsCollectionRef,
    leavesCollectionRef,
    managementEmployeeCollectionRef,
    payslipsCollectionRef,
} from "../firebase/fire-app";
import { ExcelDateToJSDate, collectionSanitizerToExcel, excelDateToJsDateConverter } from "../utilities/firebasetimestamp";
import {  useCollectionData } from "react-firebase-hooks/firestore";
import { webServerLive } from "../firebase/liveServerStatus";
import { useSSOAuth } from "../Hooks/useSSOAuth";

const ViewMasterExportImport = () => {
    const [fetchedData, loading, error] = useCollectionData(
        managementEmployeeCollectionRef,
        { idField: "id" }
    );
    const fileName = "Myeliners Aug 26";

    const { currentUser } = useSSOAuth();
    const [masterAdmin, setMasterAdmin] = useState();
    const [show, setShow] = useState(false);

    const uploadToFirestore = (data) => {
        data.forEach((doc) => {
            console.log(doc);
            doc.createdAt = excelDateToJsDateConverter(doc.createdAt);
            doc.dob = excelDateToJsDateConverter(doc.dob);
            doc.lastUpdated = excelDateToJsDateConverter(doc.lastUpdated);
            managementEmployeeCollectionRef.doc(doc.id).set(doc);
        });
    };
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheet_name_list = workbook.SheetNames;
            const xlData = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheet_name_list[0]]
            );
            uploadToFirestore(xlData);
        };
        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        // if (currentUser.email === "shahjahan.swajan@myelinh.com") {
        //     setMasterAdmin(true);
        // }
        setMasterAdmin(true);
    }, [currentUser]);
    return (
        <>
            <section className={webServerLive? "bg-primary py-3" : "bg-info py-3"}>
                <div className="container text-white d-flex justify-content-between text-white mb-0">
                    <h3>Master Export Import</h3>
                </div>
            </section>
            {masterAdmin ? (
                <section className="py-5 text-center">
                    <h2 className="text-danger">Import Section</h2>

                {webServerLive? "":<div className="container mt-4">
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => setShow(true)}
                    >
                        Employee Bulk Upload
                    </Button>

                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header className="py-1 " closeButton>
                            <h5
                                className="font-weight-bold mt-1"
                                id="example-custom-modal-styling-title"
                            >
                                Bulk Upload
                            </h5>
                        </Modal.Header>
                        <Modal.Body>
                            <input
                                type="file"
                                onChange={(e) =>
                                    handleFileUpload(e.target.files[0])
                                }
                            />
                        </Modal.Body>
                    </Modal>
                </div>}
                </section>
            ) : (
                <SectionLoading />
            )}

            {masterAdmin ? (
                <section className="py-5 bg-light text-center">
                    <div className="container">
                        <h2 className="text-danger">Export Section - {fileName}</h2>
                        {!loading &&
                            fetchedData &&
                            fetchedData.length > 0 && (
                                <ExportToExcel
                                    apiData={fetchedData}
                                    fileName={fileName}
                                />
                            )}
                    </div>
                </section>
            ) : (
                <SectionLoading />
            )}
        </>
    );
};

export default ViewMasterExportImport;

export const ExportToExcel = ({ apiData, fileName }) => {
    // alert("Hi")
    // alert(JSON.stringify(apiData))
    const sanitizedCollection = collectionSanitizerToExcel(apiData,'createdAt', 'dob', "lastUpdated");
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (sanitizedCollection, fileName) => {
        const ws = XLSX.utils.json_to_sheet(sanitizedCollection);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <button
            className="btn btn-primary"
            onClick={(e) => exportToCSV(sanitizedCollection, fileName)}
        >
            Export
        </button>
    );
};
