import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useSSOAuth } from "../../Hooks/useSSOAuth";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { cohortsList } from "../../utilities/staticData";
import {
    trainingModulesCollectionRef,
    trainingParticipantListsCollectionRef,
} from "../../firebase/fire-app";
import FunctionalSplitPopup from "./FunctionalSplitPopup";
import EligibleListViewerDataGrid from "./EligibleListViewerDataGrid";
import { useForm } from "react-hook-form";
import { jsDateToExcelDate } from "../../utilities/firebasetimestamp";
import { DateRange } from "react-date-range";
import PopupCreatingEligibleList from "./PopupCreatingEligibleList";
import PopupEligibleListCreated from "./PopupEligibleListCreated";

const ViewNewEligibleListNew = () => {
    const [batchSizeLocal, setBatchSizeLocal] = useState(0);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [popupShow, setPopupShow] = useState(false);
    const [listDonePopup, setListDonePopup] = useState(false);

    const [loading, setLoading] = useState(false);
    const { allTrainings, managementList, setManagementList } = useSSOAuth();
    const [selectedTraining, setSelectedTraining] = useState(null);
    const [functionalSplit, setFunctionalSplit] = useState(null);
    const [currentCohort, setCurrentCohort] = useState(null);
    const [eligibleList, setEligibleList] = useState([]);

    const [traingDateRange, setDateRange] = useState({
        startDate: false,
        endDate: "",
    });
    const onDateRangeChange = (value, type) => {
        setDateRange({
            ...traingDateRange,
            [type]: jsDateToExcelDate(new Date(value)),
        });
    };

    const [eligibleListCreated, setEligibleListCreated] = useState(false);

    const onTrainingModuleChange = (trainingID) => {
        const selectTraining = allTrainings.filter(
            (item) => item.id === trainingID
        )[0];
        setBatchSizeLocal(selectTraining.batchSize)
        setSelectedTraining(selectTraining);
    };

    const onCreateEligibleList = () => {
        const criteriaGradeCap = selectedTraining.gradeCap;
        const eligibleListName = currentCohort + "-" + selectedTraining.id;

        if (managementList && managementList.length > 0) {
            setEligibleList(
                managementList.filter((item) => {
                    const criteriaCompletedTrainings = item.completedTrainings;
                    // if(item.SFID === "39600191"){
                    //     alert(JSON.stringify(item.completedTrainings))
                    // }

                    if (selectedTraining.gradeCap.includes(item.payGrade)) {
                        if (criteriaCompletedTrainings) {
                            if (
                                !criteriaCompletedTrainings.includes(
                                    selectedTraining.id
                                )
                            ) {
                                return { ...item, status: "Eligible" };
                            }
                        } else {
                            return { ...item, status: "Eligible" };
                        }
                    }
                })
            );
        }
    };

    const splitAndBatchSizeChecker = () => {
        if (functionalSplit) {
            const splitData = functionalSplit;
            const assignedBatches =Object.values(splitData).reduce((acc, value) => acc + value, 0);

            // alert(assignedBatches);
            if (
                Number(batchSizeLocal) !== Number(assignedBatches)
            ) {
                if (Number(batchSizeLocal) > assignedBatches) {
                    alert("Please add more to a function!");
                }
            } else {
                // alert("ache thik");
                return true;
            }
        }
    };

    const onConfirmEligibleList = () => {
        const splitCheck = splitAndBatchSizeChecker();

        if (splitCheck) {
            setPopupShow(true);

            // alert(JSON.stringify(functionalSplit));
            if (functionalSplit) {
                setLoading(true);
                const eligibleListName =
                    currentCohort + "-" + selectedTraining.id;
                // console.log(eligibleListName);

                // add training to cohort
                // to uncomment
                trainingParticipantListsCollectionRef
                    .doc(eligibleListName)
                    .set({
                        ...selectedTraining,
                        cohort: currentCohort,
                        functionalSplit: functionalSplit,
                        ...traingDateRange,
                        trainingID: selectedTraining.id,
                        createdAt: jsDateToExcelDate(new Date()),
                        trainingPhaseStatus: "Eligible List",
                        batchSize: batchSizeLocal
                    });

                // setTimeout(()=>{
                //     setPopupShow(false)
                //     setListDonePopup(true)
                // },[8000])

                // add all employee to the list
                // to uncomment
                eligibleList.forEach(async (employee) => {
                    await trainingParticipantListsCollectionRef
                        .doc(eligibleListName)
                        .collection(eligibleListName)
                        .doc(employee.SFID)
                        .set({
                            ...employee,
                            status: "Eligible",
                            priority: Number(30),
                        });
                    if (
                        employee.SFID ===
                        eligibleList[eligibleList.length - 1].SFID
                    ) {
                        setPopupShow(false);
                        setListDonePopup(true);
                    }
                });

                // add cohort to main training list
                let allCohortsTraining;
                if (selectedTraining.cohorts) {
                    allCohortsTraining = [
                        ...new Set([
                            ...selectedTraining?.cohorts,
                            currentCohort,
                        ]),
                    ];
                } else {
                    allCohortsTraining = [currentCohort];
                }

                // to uncomment
                trainingModulesCollectionRef.doc(selectedTraining.id).update({
                    cohorts: allCohortsTraining,
                });
                // alert(allCohortsTraining);
            } else {
                setPopupShow(false);
                alert("Please update functional split -.-");
            }
        } else {
            alert("Functional split is not updated properly.");
        }
    };
    const onCohortChange = (cohort) => {
        setCurrentCohort(cohort);
    };

    const onFormEligibleListCreate = (data, e) => {
        onCreateEligibleList();
    };
    const onRemoveListItem = (rowInfo) => {
        const objectIndex = eligibleList.findIndex(
            (object) => object.SFID === rowInfo.SFID
        );
        if (objectIndex !== -1) {
            const newList = [...eligibleList];
            newList.splice(objectIndex, 1);
            setEligibleList([...newList]);
        }
    };
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-around">
                        <h3 className="mb-0">Create Eligible List</h3>
                        {eligibleList && eligibleList.length > 0 && (
                            <h3 className="mb-0 ">
                                <span className="badge bg-danger">
                                    {eligibleList.length}
                                </span>
                            </h3>
                        )}
                    </div>
                    <hr className="my-0" />
                </section>
                <PopupCreatingEligibleList popupShow={popupShow} />
                <PopupEligibleListCreated popupShow={listDonePopup} />
                <section className="py-3">
                    <div className="container">
                        <form
                            onSubmit={handleSubmit(onFormEligibleListCreate)}
                            className="row"
                        >
                            <InputWrapper col="4" label="Training">
                                <select
                                    onChange={(e) =>
                                        onTrainingModuleChange(e.target.value)
                                    }
                                    name="module"
                                    className="form-control"
                                >
                                    <option>Select training ...</option>
                                    {allTrainings &&
                                        allTrainings.map((item) => (
                                            <option value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </InputWrapper>
                            <InputWrapper col="2" label="Q">
                                <select
                                    onChange={(e) =>
                                        onCohortChange(e.target.value)
                                    }
                                    name="module"
                                    className="form-control"
                                >
                                    <option>Select quarter ...</option>
                                    {cohortsList &&
                                        cohortsList.map((item) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                </select>
                            </InputWrapper>

                            <InputWrapper col="3" label="Start Date">
                                <input
                                    onChange={(e) =>
                                        onDateRangeChange(
                                            e.target.value,
                                            "startDate"
                                        )
                                    }
                                    type="date"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <InputWrapper col="3" label="End Date">
                                <input
                                    onChange={(e) =>
                                        onDateRangeChange(
                                            e.target.value,
                                            "endDate"
                                        )
                                    }
                                    type="date"
                                    className="form-control"
                                />
                            </InputWrapper>
                            <div className="col-md-12 mb-4 text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-sm"
                                >
                                    Get Eligible List
                                </button>
                            </div>
                        </form>
                        {eligibleList && (
                            <EligibleListViewerDataGrid
                                managementList={eligibleList}
                                onRemoveListItem={onRemoveListItem}
                            />
                        )}
                        {selectedTraining && eligibleList && batchSizeLocal && (
                            <FunctionalSplitPopup
                                addClass="mt-4"
                                setBatchSizeLocal={setBatchSizeLocal}
                                batchSize={batchSizeLocal}
                                setFunctionalSplit={setFunctionalSplit}
                                functionalSplit={functionalSplit}
                            />
                        )}
                        {eligibleList && functionalSplit && (
                            <div className="text-center mt-4">
                                {loading ? (
                                    <button
                                        onClick={onConfirmEligibleList}
                                        className="btn-primary btn"
                                        disabled
                                    >
                                        Confirm Eligible List
                                    </button>
                                ) : (
                                    <button
                                        onClick={onConfirmEligibleList}
                                        className="btn-primary btn"
                                    >
                                        Confirm Eligible List
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewNewEligibleListNew;
