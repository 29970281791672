import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import * as XLSX from "xlsx/xlsx.mjs";
import { excelDateToJsDateConverter } from "../../utilities/firebasetimestamp";
import { managementEmployeeCollectionRef } from "../../firebase/fire-app";
import SectionLoading from "../../Components/SectionLoading";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import firebase from "firebase";
import UploaderManagementList from "./UploaderManagementList";
import UploaderLeaver from "./UploaderLeaver";
import UploaderTrainingCompletion from "./UploaderTrainingCompletion";
import UploaderHRBPChange from "./UploaderHRBPChange";
import UploaderUnitHeadChange from "./UploaderUnitHeadChange";

const ViewMassUpload = () => {
    const changeTypes = [
        "Select uploader",
        "Management List",
        "Training Completion List",
        "Unit Head List",
        "HRBP List",
        "Leavers List",
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [massUploadList, setMassUploadList] = useState([]);
    const [currentUploadModule, setCurrentUploadModule] = useState(null);
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheet_name_list = workbook.SheetNames;
            const xlData = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheet_name_list[0]]
            );
            setMassUploadList(xlData);
        };
        reader.readAsArrayBuffer(file);
    };
    const uploadManagementList = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            managementEmployeeCollectionRef.doc(doc.SFID).set(doc);
        });
        setIsLoading(false);
    };
// done
    const massUploadTrainingCompletion = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            if (doc.completedTraining) {
                managementEmployeeCollectionRef.doc(doc.SFID).update({
                    completedTrainings:
                        firebase.firestore.FieldValue.arrayUnion(
                            doc.completedTraining
                        ),
                });
            }
        });
        setIsLoading(false);
    };

    // done
    const massUploadHRBPChange = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            if (doc.hrbp) {
                managementEmployeeCollectionRef.doc(doc.SFID).update({
                    hrbp: doc.hrbp,
                });
            }
        });
        setIsLoading(false);
    };
    // done
    const massUploadUnitHeadChange = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            if (doc.unitHead) {
                managementEmployeeCollectionRef.doc(doc.SFID).update({
                    unitHead: doc.unitHead,
                });
            }
        });
        setIsLoading(false);
    };
    const hrbpUnitHeadMassUpload = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            if (doc.unitHeadSF) {
                managementEmployeeCollectionRef.doc(doc.SFID).update({
                    hrbpSF: doc.hrbpSF,
                    hrbpName: doc.hrbpName,
                    unitHeadSF: doc.unitHeadSF,
                    unitHeadName: doc.unitHeadName,
                    designation: doc.designation,
                    department: doc.department,
                });
            }
        });
        setIsLoading(false);
    };
    const massUploadLeaverEmployees = () => {
        setIsLoading(true);
        massUploadList.forEach((doc) => {
            if (doc.unitHead) {
                managementEmployeeCollectionRef.doc(doc.SFID).update({
                    employment: "Terminated",
                });
            }
        });
        setIsLoading(false);
    };

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">
                            Mass Upload Interface {currentUploadModule && " - "}{" "}
                            {currentUploadModule && currentUploadModule}{" "}
                        </h3>

                        {/* <hr className="mb-0" /> */}
                    </div>
                    <hr className="my-0" />
                </section>
                <section className="py-4">
                    <div className="container">
                        <div className="row">
                            <InputWrapper col="5" label="Type">
                                <select
                                    name="change"
                                    id=""
                                    className="form-control"
                                    onChange={(e) =>
                                        setCurrentUploadModule(e.target.value)
                                    }
                                >
                                    {changeTypes.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </InputWrapper>
                        </div>
                    </div>
                </section>

                {/* {isLoading && <SectionLoading />} */}

                {currentUploadModule === "Management List" && (
                    <UploaderManagementList />
                )}
                {currentUploadModule === "Leavers List" && <UploaderLeaver />}
                {currentUploadModule === "Training Completion List" && (
                    <UploaderTrainingCompletion />
                )}
                {currentUploadModule === "HRBP List" && (
                    <UploaderHRBPChange />
                )}
                {currentUploadModule === "Unit Head List" && (
                    <UploaderUnitHeadChange />
                )}
            </SidebarComponent>
        </>
    );
};

export default ViewMassUpload;
