import React from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import BulkUploadEmployeePopup from "./BulkUploadEmployeePopup";
import ManagementListDataGrid from "./ManagementListDataGrid";

const ViewManagementList = () => {
    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">Management List</h3>
                    </div>
                    <hr className="my-0" />
                </section>
                <section className="py-3">
                    <ManagementListDataGrid/>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewManagementList;
