import React, { useEffect, useState } from "react";
import {
    adminsCollectionRef,
    managementEmployeeCollectionRef,
} from "../../firebase/fire-app";
import SelectSearch from "react-select-search";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "react-select-search/style.css";
import "./style.scss";
import PopupAddHRBP from "./PopupAddHRBP";
import PopupAddUnitHead from "./PopupAddUnitHead";
const UserGroupUnitHead = ({ users }) => {
    const [hrPeople, loading, error] = useCollectionData(
        managementEmployeeCollectionRef,
        { idField: "id" }
    );
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedFunction, setSelectedFunction] = useState(null);
    const [searchableOptions, setSearchableOptions] = useState(null);

    useEffect(() => {
        if (hrPeople && hrPeople.length > 0) {
            setSearchableOptions(
                hrPeople.map((hr) => ({ name: hr.name, value: hr.email }))
            );
        }
    }, [hrPeople]);
    return (
        <>
            <section className="py-4">
                {searchableOptions && (
                    <PopupAddUnitHead
                        searchableOptions={searchableOptions}
                        hrPeople={hrPeople}
                    />
                )}
                <div className="container">
                    <table class="table pr-5 table-bordered border-primary table-hover table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>SFID</th>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Unit</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users &&
                                users.map((user, index) => (
                                    <TableRow user={user} index={index} />
                                ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};

export default UserGroupUnitHead;

const TableRow = ({ user, index }) => {
    const removeUser = () => {
        adminsCollectionRef
            .doc(user.id)
            .delete()
            .then(() => {
                alert(`HRBP ${user.id} deleted successfully!`);
            });
    };
    return (
        <tr>
            <td>{index + 1}</td>
            <td>{user.SFID}</td>
            <td>{user.id}</td>
            <td>{user.name}</td>
            <td>{user.unit}</td>
            <td>
                <button
                    onClick={removeUser}
                    className="btn-sm btn btn-outline-danger"
                >
                    REMOVE
                </button>
            </td>
        </tr>
    );
};
