const managementGrades = [
    { label: "30", value: "30" },
    { label: "31", value: "31" },
    { label: "32", value: "32" },
    { label: "33", value: "33" },
    { label: "GRMT", value: "GRMT" },
    { label: "34", value: "34" },
    { label: "35", value: "35" },
    { label: "36", value: "36" },
    { label: "37", value: "37" },
    { label: "38", value: "38" },
    { label: "38", value: "40" },
]

const cohortsList = ["Q4-2023","Q1-2024","Q2-2024","Q3-2024","Q4-2024"]

export {managementGrades, cohortsList}