import React, { useEffect, useState } from "react";
// import DashboardLayout from '../Components/DashboardLayout/DashboardLayout';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ViewDashboard from "./ViewDashboard.js/ViewDashboard";
import { useAuth } from "../Hooks/useAuth";
import ViewMasterExportImport from "./ViewMasterExportImport";
import ViewTrainingModules from "./ViewTrainingModules/ViewTrainingModules";
import ViewManagementList from "./ViewManagementList/ViewManagementList";
import ViewTrainingCohort from "./ViewTrainingCohort/ViewTrainingCohort";
import ViewCreateEligibleList from "./ViewCreateEligibleList/ViewCreateEligibleList";
import { useSSOAuth } from "../Hooks/useSSOAuth";
import ViewLogin from "./LoginPage/ViewLogin";
import ViewUnitHeadShortlist from "./ViewUnitHeadShortlist.js/ViewUnitHeadShortlist";
import ViewHRBPPortal from "./ViewHRBPPortal/ViewHRBPPortal";
import ViewNewEligibleListNew from "./ViewCreateEligibleList/ViewNewEligibleListNew";
import ViewMassUpload from "./ViewMassUpload/ViewMassUpload";
import ViewAdminTrainingCompletion from "./ViewAdminTrainingCompletion/ViewAdminFinalList";
import ViewAdminFinalList from "./ViewAdminFinalList/ViewAdminFinalList";
import ViewAccessControl from "./ViewAccessControl/ViewAccessControl";
import ViewTrainingCalendar from "./ViewTrainingCalendar/ViewTrainingCalendar";

const Main = () => {
    const {
        currentUser,
        setCurrentUser,
        hasAccess,
        setHasAccess,
        // isAdmin,
        // setIsAdmin,
    } = useSSOAuth();

    const [empID, setEmpID] = useState(null);
    const [empEmail, setEmpEmail] = useState(null);
    const giveAccess = true;

    useEffect(() => {
        if (currentUser && currentUser.empID && currentUser.email) {
            setEmpEmail(currentUser.email);
            setEmpID(currentUser.empID);
        }
    }, [currentUser]);
    const isAdmin = true;
    return (
        <>
            <Router>
                <Routes>
                    {/* {giveAccess && currentUser && empEmail && <Route path="/personal-profile" element={<ViewPersonalProfile email={empEmail} />} />} */}
                    {/* {giveAccess && currentUser && empID && <Route path="/claim-management" element={<ViewClaimManagement empID={empID?.empID} />} />} */}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/training-modules"
                            element={<ViewTrainingModules />}
                        />
                    )}
                    {giveAccess && isAdmin && <Route path="/admin/management-list" element={<ViewManagementList />} />}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/training-cohort"
                            element={<ViewTrainingCohort />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/create-eligible-list"
                            element={<ViewNewEligibleListNew />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/shortlist-nominations"
                            element={<ViewUnitHeadShortlist />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/hrbp-portal"
                            element={<ViewHRBPPortal />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/admin/ongoing-nomination-list"
                            element={<ViewAdminFinalList />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/admin/completed-trainings"
                            element={<ViewAdminTrainingCompletion />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/admin/mass-upload"
                            element={<ViewMassUpload />}
                        />
                    )}
                    {giveAccess && isAdmin && (
                        <Route
                            path="/admin/access-control"
                            element={<ViewAccessControl />}
                        />
                    )}

                    {giveAccess && isAdmin && (
                        <Route
                            path="/master/9830088"
                            element={<ViewMasterExportImport />}
                        />
                    )}
                    <Route path="/login" element={<ViewLogin />} />

                    {giveAccess && (
                        <Route path="/" element={<ViewDashboard />} />
                    )}
                    {giveAccess && (
                        <Route path="/training-calendar" element={<ViewTrainingCalendar />} />
                    )}
                    <Route path="*" element={<ViewLogin />} />
                </Routes>
            </Router>
        </>
    );
};

export default Main;
