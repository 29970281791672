import React, { useEffect, useState } from "react";
import HRBPDataGrid from "./HRBPDataGrid";
import { trainingParticipantListsCollectionRef } from "../../firebase/fire-app";
import FunctionalSplitComp from "./FunctionalSplitComp";
import TrainingDetailsForTabs from "./TrainingDetailsForTabs";

const HRBPTab = ({ cohort, currentUser, hrbp, trainingDetails }) => {
    const [eligibleListCreated, setEligibleListCreated] = useState(false);
    const [functionalSplit, setFunctionalSplit] = useState(null);
    const [nominatedArray, setNominatedArray] = useState([]);

    const [eligibleListRef, setEligibleListRef] = useState(
        cohort + "-" + trainingDetails.id
    );

    const [selectedTraining, setSelectedTraining] = useState(null);

    function findProperty(email, obj) {
        for (const property in obj) {
            if (obj[property].includes(email)) {
                return property;
            }
        }
        return null;
    }

    useEffect(() => {
        if (trainingDetails) {
            setSelectedTraining(trainingDetails);
        }
    }, [trainingDetails]);

    useEffect(() => {
        if (cohort && trainingDetails) {
            setEligibleListRef(`${cohort}-${trainingDetails?.id}`);
        }
    }, [cohort, trainingDetails]);
    const [mySplitCount, setMySplitCount] = useState(0);

    const [mySplit, setMySplit] = useState(null);

    useEffect(() => {
        if ((hrbp, currentUser)) {
            // alert(JSON.stringify(hrbp));
            const mySF = currentUser.SFID;
            const myFunction = findProperty(mySF, hrbp);
            setMySplit(myFunction);
            const splitDetails = trainingDetails.functionalSplit;
            const ownSplit = splitDetails[mySplit]
            // alert(ownSplit)
            setMySplitCount(ownSplit)
        }
    }, [hrbp, currentUser]);

    return (
        <>
            <section>
            
                <div className="container-fluid">
                    {/* <TrainingDetailsForTabs data={trainingDetails}/> */}
                    <FunctionalSplitComp
                        mySplit={mySplit}
                        nominatedArray={nominatedArray}
                        data={trainingDetails.functionalSplit}
                        trainingDetails={trainingDetails}
                    />
                    
                    {trainingDetails && hrbp && currentUser && (
                        <HRBPDataGrid
                            userSFID={currentUser.SFID}
                            nominatedArray={nominatedArray}
                            mySplit={mySplit}
                            splitDetails={trainingDetails.functionalSplit}
                            setNominatedArray={setNominatedArray}
                            shortlistRef={trainingDetails.id}
                        />
                    )}
                </div>
              
            </section>
        </>
    );
};

export default HRBPTab;

