import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import UserGroupUnitHead from "./UserGroupUnitHead";
import UserGroupHRBP from "./UserGroupHRBP";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { adminsCollectionRef } from "../../firebase/fire-app";

const ViewAccessControl = () => {
    const [userGroup, setUserGroup] = useState("UnitHead");

    const [authUsers, loading, error] = useCollectionData(adminsCollectionRef, {
        idField: "id",
    });

    const [hrbpUsers, setHrbpUsers] = useState(null);
    const [unitHeadUsers, setUnitHeadUsers] = useState(null);

    useEffect(() => {
        if (authUsers && authUsers.length > 0) {
            setHrbpUsers(authUsers.filter((user) => user.hrbp));
            setUnitHeadUsers(authUsers.filter((user) => user.unitHead));
        }
    }, [authUsers]);

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top  bg-light">
                    <div className="container py-3 d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">
                            User Access Control - {userGroup}
                        </h3>
                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                onClick={() => setUserGroup("UnitHead")}
                                type="button"
                                className={`btn btn-${
                                    userGroup === "UnitHead"
                                        ? "primary"
                                        : "light"
                                }`}
                            >
                                Unit Head
                            </button>
                            <button
                                onClick={() => setUserGroup("HRBP")}
                                type="button"
                                className={`btn btn-${
                                    userGroup === "HRBP" ? "primary" : "light"
                                }`}
                            >
                                HRBP
                            </button>
                        </div>
                    </div>
                    <hr className="my-0" />
                </section>

                {hrbpUsers && userGroup === "HRBP" && <UserGroupHRBP users={hrbpUsers} />}
                {unitHeadUsers && userGroup === "UnitHead" && (
                    <UserGroupUnitHead users={unitHeadUsers} />
                )}
            </SidebarComponent>
        </>
    );
};

export default ViewAccessControl;
