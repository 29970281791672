import React from "react";
import DashboardLayout from "../../Components/DashboardLayout/DashboardLayout";
import "./style.scss";
import { Link } from "react-router-dom";
import dashboardMenu from "./DataDashboardMenu";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useAuth } from "../../Hooks/useAuth";
import { webServerLive } from "../../firebase/liveServerStatus";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import { useSSOAuth } from "../../Hooks/useSSOAuth";
import ViewLogin from "../LoginPage/ViewLogin";
import adminSidebarMenus from "./DataAdminMenus";
import { hrbpMenus, unitHeadMenus } from "./allDashboardMenus";

const ViewDashboard = () => {
    const { isAdmin, setIsAdmin, currentUser } = useSSOAuth();
    const onLogOut = () => {
        firebase
            .auth()
            .signOut()
            .then((res) => {
                sessionStorage.removeItem("token");
                setIsAdmin(false);
                window.location.href = "/";
            })
            .catch((err) => {
                alert(err);
            });
    };

    // Create a transporter using SMTP details

    // Define the email content

    // Send the email

    const onTriggerEmail = () => {
        const mailDetails = {
            to: "smswajan@gmail.com",
            message: {
                subject: "Hello from Firebase!",
                html: "This is an <b>HTML</b> email body.",
                text: "This is a plaintext body.",
            },
        };
        // customMailCollectionRef.add(mailDetails).then(res => {
        //     alert("Email Triggered")
        // })
        // transporter.sendMail(mailOptions, (error, info) => {
        //     if (error) {
        //         console.error('Error sending email:', error);
        //     } else {
        //         console.log('Email sent:', info.response);
        //     }
        // });
    };

    return (
        <>
            <AuthenticatedTemplate>
                <section
                    className={
                        webServerLive ? "bg-primary py-3" : "bg-info py-3"
                    }
                >
                    <div className="container text-white d-flex justify-content-between text-white mb-0">
                        <h3>BATB HR Employee Center {isAdmin && "Admin"}</h3>

                        <button onClick={onLogOut} className="btn btn-primary">
                            Logout
                        </button>
                    </div>
                </section>
                <section>
                    <div className="container mt-4">
                        {/* <DataExporterExcel/> */}
                        <div className="row">
                            {dashboardMenu.map((item) => (
                                <DashboardMenu key={item.id} data={item} />
                            ))}
                            {currentUser?.admin &&
                                adminSidebarMenus.map((item) => (
                                    <DashboardMenu key={item.key} data={item} />
                                ))}
                            {currentUser?.hrbp &&
                                hrbpMenus.map((item) => (
                                    <DashboardMenu key={item.key} data={item} />
                                ))}
                            {currentUser?.unitHead &&
                                unitHeadMenus.map((item) => (
                                    <DashboardMenu key={item.key} data={item} />
                                ))}
                        </div>
                    </div>
                </section>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <ViewLogin />
            </UnauthenticatedTemplate>
        </>
    );
};

export default ViewDashboard;

const DashboardMenu = ({ data }) => {
    return (
        <div className="col-md-4 text-white p-3">
            <Link to={data.href} className="text-white dashboard-link">
                <div className="d-flex dashboard-menu py-5 justify-content-center align-items-center bg-primary">
                    <h3 className="py-4">{data.text}</h3>
                </div>
            </Link>
        </div>
    );
};
