import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputWrapper from "../../Components/FormComponents/InputWrapper";

import {
    configurationDataRef,
    managementEmployeeCollectionRef,
} from "../../firebase/fire-app";
import { cohortsList } from "../../utilities/staticData";

const CohortSetupPopup = ({ currentCohort }) => {
    const [show, setShow] = useState(false);

    const onCohortChange = (e) => {
        configurationDataRef
            .doc("webConfiguration")
            .update({ currentCohort: e.target.value })
            .then(() => {
                setShow(false);
            });
    };
    return (
        <>
            <button
                className="btn btn-primary btn-sm "
                onClick={() => setShow(true)}
            >
                Change Cohort
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Bulk Upload Management List
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <InputWrapper col="7" label="Cohort: ">
                                <select
                                    className="form-control"
                                    onChange={(e) => onCohortChange(e)}
                                    name="cohort"
                                >
                                    {cohortsList.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </InputWrapper>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CohortSetupPopup;
