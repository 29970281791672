import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

const PopupNominationConfirmed = ( ) => {
    const [show, setShow] = useState(false);
    
    const onClose = () => {
        setShow(false)
        window.location.reload();
    };

    return (
        <>
        <button onClick={()=>setShow(!show)} className="btn-primary btn btn-sm">CONFIRM NOMINATIONS</button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                backdrop="static"
                centered
                aria-labelledby="example-custom-modal-styling-title"
            >
                {/* <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add Document -
                    </h5>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="d-flex py-5 justify-content-center">
                        <div className="text-center">
                            <h3 className="font-bold text-success">
                                Nomination Confirmed!!
                            </h3>
                            <h3>😁</h3>
                            <button
                                onClick={onClose}
                                className="btn-outline-success btn mt-4"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupNominationConfirmed;
