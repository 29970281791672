import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


const TrainingDetailsPopup = ({data}) => {
    const [show,setShow] = useState(false)
    return (
        <>
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                View Training Details
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        {data?.name}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    {data && (
                        <div className="card font-16 p-3 bg-light my-3">
                            <div className="d-flex justify-content-between ">
                                <p className="mb-0">
                                    Training Name:{" "}
                                    <span className="text-primary font-bold">
                                        {data.name}
                                    </span>
                                </p>
                                <p className="mb-0">
                                    Batch size:{" "}
                                    <span className="text-primary font-bold">
                                        {data.batchSize}
                                    </span>
                                </p>
                                <p>
                                    Eligible Grades:{" "}
                                    {data.gradeCap.map((item) => (
                                        <span className="font-bold text-primary">
                                            {item},{" "}
                                        </span>
                                    ))}
                                </p>
                            </div>
                            <div className="d-flex justify-content-around    ">
                                <p className="mb-0">
                                    Corporate:{" "}
                                    <span className="text-primary font-bold">
                                        {data.functionalSplit.Corporate} {"   "}
                                    </span>
                                </p>

                                <p className="mb-0">
                                    Marketing & Commercial:{" "}
                                    <span className="text-primary font-bold">
                                        {
                                            data.functionalSplit
                                                .MarketingCommercial
                                        }
                                    </span>
                                </p>
                                <p className="mb-0">
                                    Operations:{" "}
                                    <span className="text-primary font-bold">
                                        {data.functionalSplit.Operations}
                                    </span>
                                </p>
                                <p className="mb-0">
                                    Leaf:{" "}
                                    <span className="text-primary font-bold">
                                        {data.functionalSplit.Leaf}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TrainingDetailsPopup;