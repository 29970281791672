import "./style.scss";


const InputWrapper = ({ col, label, addClass, children, mb0 }) => {
    return (
        <div
            className={
                "mb-3 col-md-" + col + " " + addClass
            }
        >
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">{label} </span>
                </div>
                {children}
            </div>
        </div>
    );
};

export default InputWrapper;