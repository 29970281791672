import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectSearch from "react-select-search";
import { adminsCollectionRef } from "../../firebase/fire-app";

const PopupAddUnitHead = ({ searchableOptions, hrPeople }) => {
    const [show, setShow] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedFunction, setSelectedFunction] = useState(null);

    const allUnits = ["HR", "LEX", "FINANCE", "IDT", "MARKETING", "COMMERCIAL", "QUALITY", "PROCUREMENT", "PRODUCT", "ADMIN", "LEAF"]

    const unitsOptions = allUnits.map(unit => ({
        name: unit,
        value: unit
      }));

    const addNewHRBP =()=>{
        if(selectedUser && selectedFunction){
            const employeeDetails = hrPeople.filter(emp => emp.email===selectedUser)[0];
            adminsCollectionRef.doc(selectedUser).get() 
            .then((docSnapshot)=>{
                if(docSnapshot.exists){
                    alert("HRBP already added!")
                }else{
                    adminsCollectionRef.doc(selectedUser).set({
                        unitHead: true,
                        unit:selectedFunction,
                        name: employeeDetails.name,
                        SFID: employeeDetails.id
                    })
                    .then(()=>{
                        alert("HRBP added successfully!")
                        setShow(false)
                    })
                    .catch((err)=>{
                        alert(err.message)
                    })
                }
            })


        }else{
            alert("Please select user and function!")
        }
    }

    return (
        <>
        <div className="container mb-3">
        <Button variant="primary" size="sm" onClick={() => setShow(true)}>
                Add Unit Head
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add Unit Head
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-around align-items-center">
                        {searchableOptions && (
                            <SelectSearch
                                options={searchableOptions}
                                search
                                value={selectedUser}
                                placeholder="name"
                                onChange={setSelectedUser}
                            />
                        )}

                        <SelectSearch
                            options={unitsOptions}
                            search
                            value={selectedFunction}
                            placeholder="unit name"
                            onChange={setSelectedFunction}
                        />

                        <button onClick={addNewHRBP} className=" btn btn-outline-primary">
                            Add Unit Head
                        </button>
                    </div>
                    <div className="text-center"></div>
                </Modal.Body>
            </Modal>
        </div>
            
        </>
    );
};

export default PopupAddUnitHead;
